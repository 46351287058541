/* ==========================================================================
   # START ALERT COMPONENT
   ==========================================================================
*/
.a-alert {
    @include border-radius(.4rem);

    &--error {
        @include border-radius(.4rem);
    }

    &--scan-export {
        width: 100%;
        margin-top: -5rem;
        margin-bottom: 1.6rem;
    }
}

.ant-notification-notice-message {
    font: {
        size: font-size(medium) !important;
    }
}
.a-notification-dot {
    position: relative;
    &:before{
        position: absolute;
        top: calc(50% - 4px);
        left: 0;
        content: '';
        width: .8rem;
        height: .8rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: transparent;
    }
    &.animation {
        &:before {
            -webkit-animation: opacity-shadow .8s linear infinite;
            -o-animation: opacity-shadow .8s linear infinite;
            animation: opacity-shadow .8s linear infinite;
        }
    }
    &.active{
        &:before{
            background-color: color(light-green);
        }
    }
    &.unactive{
        &:before{
            background-color: color(gray);
        }
    }
    &.red {
        &:before{
            background-color: rgba(color(red),1);
        }
    }
    &.blue {
        &:before{
            background-color: color(primary);
        }
    }
    &.orange {
        &:before{
            background-color: color(orange-FAA);
        }
    }
    &.green {
        &:before{
            background-color: color(light-green);
        }
    }
}


/*Icon chuông thông báo trên header*/
.a-notification {
    &__icon-svg {
        &.active {
            path{
                fill:color(primary);
            }
        }
    }
    &__global {
        &__popover{
            width: 43rem;
            .ant-popover-inner-content {
                padding: 0;
            }
        }
    }
}
.ant-badge-count {
    z-index: 10;
    min-width: 12px;
    height: 12px;
    padding: 0 4px;
    color: #fff;
    font-weight: normal;
    font-size: 8px;
    line-height: 13px;
    white-space: nowrap;
    text-align: center;
    background: #f5222d;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
}

.list-attention {
    padding: 0 16px 13px 16px;
    .attention__item {
        &:not(:last-child) {
            margin-bottom: 1.2rem;
        }

        font-size: font-size(medium);
        line-height: 1.9rem;

        .package-note__icon {
            margin-top: .3rem;
        }

        &__info-top {
            margin-bottom: 0;

            .info-top-user {
                font-size: font-size(s-small);
                font-weight: 500;
                color: rgba(color(black),.65);
            }

            .info-top-time {
                font-size: font-size(s-small);
                color: rgba(color(black),.45);
                font-weight: 400 ;
            }
        }
        &__desc {
            margin-bottom: 0;
        }
    }

}

.note-icon {
    margin-top: .3rem;

    &--deleted {
        path {
            fill: color(gray-D9)
        }
    }

    &--warning {
        path {
            fill: red
        }
    }
}


/*Content thông báo trên phần header*/
/* ==========================================================================
   # END ALERT COMPONENT
   ==========================================================================
*/
