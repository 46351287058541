/* ==========================================================================
   # START LOADING COMPONENT
   ==========================================================================
*/
.icon-loading {
    z-index: z-index(dropdown);
    svg {
        animation: circle .5s linear reverse infinite;
    }
}
.a-form--loading {
    &.loading-input {
        position: relative;
        .loading {
            pointer-events: none;
            background-color: color(blue-F0);
            &:hover {
                pointer-events: none;
                cursor: default;
            }
        }
        .icon-loading {
            position: absolute;
            top: 1rem !important;
            right: .5rem;
        }
    }
    &.loading-texarea {
        position: relative;
        .loading {
            pointer-events: none;
            background-color: color(blue-F0);
            &:hover {
                pointer-events: none;
                cursor: default;
            }
        }
        .icon-loading {
            position: absolute;
            top:-1rem !important;
            right: 1rem;
        }
    }
    &.loading-defaut{
        position: relative;
        .loading {
            pointer-events: none;
            background-color: color(blue-F0);
            &:hover {
                pointer-events: none;
                cursor: default;
            }
        }
        .icon-loading {
            position: absolute;
            top: .2rem;
            right: 1rem;
        }
    }
    &.loading--volumn-package {
        position: relative;
        .icon-loading {
            position: absolute;
            top: .3rem;
            right: 6rem;
        }
    }
}
.a-loading-page{
    &__icon{
        height: 4px;
        width: 100%;
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 5.5rem;
        background-color: transparent;
        z-index: 9999;
        &::before{
            display: block;
            position: absolute;
            content: "";
            left: -200px;
            width: 200px;
            height: 4px;
            background-color: color(primary);
            animation: loading 1.5s linear infinite;
        }
    }
}

//Loading trong skeleton
.askeleton-avatar{
    .ant-skeleton-content {
        .ant-skeleton-title{
            margin-top: 0;
        }
        .ant-skeleton-paragraph{
            margin-top: 1rem !important;
        }
    }
}
.askeleton-list{
    .ant-skeleton-content {
        .ant-skeleton-title{
            margin-top: 0;
        }
        .ant-skeleton-paragraph{
            margin-top: 1rem !important;
        }
    }
}
.askeleton-item-table{
    .ant-skeleton-content {
        .ant-skeleton-title{
            margin-top: 0;
        }
        .ant-skeleton-paragraph{
            margin-top: 0 !important;
            margin-bottom: 0 ;
        }
    }
}

.loading-check-package {
    &:after {
        content: '. ';
        animation: dots 1s steps(5, end) infinite;
    }

}

.ant-skeleton-paragraph-m0 {
    margin-top: 0 !important;
    margin-bottom: 0;
    width: 100%
}
/* ==========================================================================
   # END LOADING COMPONENT
   ==========================================================================
*/
