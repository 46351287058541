.a-pagination {
    @include a-flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    margin-bottom: -2.4rem;
    padding: 1.6rem;
    @include box-shadow(0, 0, .4rem, rgba(color(black), .16));

    &--fixed {
        position: fixed;
        bottom: 0;
        right: 2.4rem;
        margin: 0;
        width: calc(100% - #{ get-map($widths, "sider-main-collapsed", "lg")} - 4.8rem);
        background-color: color(white);
        z-index: 1;
        @include media-breakpoint-down(lg) {
            right: 1.6rem;
            width: calc(100% - #{ get-map($widths, "sider-main-collapsed", "lg")} - 3.2rem);
        }
        @include media-breakpoint-up(xxk) {
            right: calc((100vw - 2560px) / 2 + 2.4rem);
            width: calc(2560px - 55px - 4.8rem);
        }
    }

    &.is-sidebar-left {
        width: calc(100% - #{ get-map($widths, "sider-main-collapsed", "lg")} - #{ get-map($widths,"sider","xxl")} - 7.2rem);
    }

    &.detail-bag {
        margin-right: -1.7rem;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    }

    &.is-sidebar {
        @include media-breakpoint-down(lg) {
            flex-direction: column-reverse;
            .a-pagination--select-number-item {
                margin-bottom: 1.2rem;
            }
        }
    }

    &--select-number-item {
        margin-left: 2.4rem;
        @include media-breakpoint-down(lg) {
            margin-right: 0;
        }

        .text-show {
            margin-right: 1rem;
            font: {
                weight: font-weight(regular);
            }
            color: rgba(color(black), .85);
        }
    }

    &--detail {
        .ant-pagination-item {
            @include border-radius(.6rem);

            &:hover {
                border-color: color(primary);
            }

            a {
                color: rgba(color(black), .85);
            }

            &.ant-pagination-item-active {
                background-color: color(primary);
                border-color: color(primary);

                a {
                    color: color(white);
                }
            }
        }
    }

    /*Pagination danh sách đơn hàng trong chi tiết kiện*/
    &--order-package {
        @include a-flex;
        margin-left: 1.34rem;

        .ant-pagination-prev {
            a {
                border: none;
            }
        }

        .ant-pagination-next {
            margin-right: -1.2rem;

            a {
                border: none;
            }
        }

        .ant-pagination-item {
            @include border-radius(.6rem);

            &:hover {
                border-color: color(primary);
            }

            a {
                color: rgba(color(black), .85);
            }

            &.ant-pagination-item-active {
                background-color: color(primary);
                border-color: color(primary);

                a {
                    color: color(white);
                }
            }
        }
    }

    /*Pagination trong modal chọn mẫu tạo bao */
    &--modal-template-bag {
        padding: 1.6rem;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        @include box-shadow(0, -.1rem, .6rem, rgba(color(black), .16));
    }

    &--height-action {
        height: 6.5rem;
    }
}
