@import "layout.header";
@import "layout.sider";
@import "layout.footer";
@import "layout.content";
@import "layout.content";

#page-content {
    & > .ant-layout {
        &-has-sider {
            min-height: 100vh;
        }
    }
}

.ant-layout {
    &.has-sub-sider {
        &.a-scroll--is-sider {
            .a-sub-main {
                margin-left: calc(#{get-map($widths, 'sider', 'xxl')} + 1.6rem);
            }
        }

        main.a-sub-main {
            border-top-left-radius: .4rem;
            border-top-right-radius: .4rem;
            margin-left: 1.6rem;
            margin-right: 1.6rem;
        }

        .a-sub-main {
            &__header {
                padding: 1.6rem;

                &.has-border-bottom {
                    border-bottom: 1px solid rgba(color(black), .14);
                }

                .title {
                    margin-bottom: 0;
                }
            }

            &__body {
                padding: 1.6rem;
            }
        }
    }
}
