/* ==========================================================================
   # START MARGIN ULTILITIES
   ==========================================================================
*/

.m {
    &-0 {
        margin: 0 !important;
    }

    &-1 {
        margin: (1rem * .25) !important;
    }

    &-2 {
        margin: (2rem * .25) !important;
    }

    &-3 {
        margin: (3rem * .25) !important;
    }

    &-4 {
        margin: (4rem * .25) !important;
    }

    &-5 {
        margin: (5rem * .25) !important;
    }

    &-6 {
        margin: (6rem * .25) !important;
    }

    &-7 {
        margin: (7rem * .25) !important;
    }

    &-8 {
        margin: (8rem * .25) !important;
    }

    &t {
        &-0 {
            margin-top: 0 !important;
        }

        &-1 {
            margin-top: (1rem * .25) !important;
        }

        &-2 {
            margin-top: (2rem * .25) !important;
        }

        &-3 {
            margin-top: (3rem * .25) !important;
        }

        &-4 {
            margin-top: (4rem * .25) !important;
        }

        &-5 {
            margin-top: (5rem * .25) !important;
        }

        &-6 {
            margin-top: (6rem * .25) !important;
        }

        &-7 {
            margin-top: (7rem * .25) !important;
        }

        &-8 {
            margin-top: (8rem * .25) !important;
        }
    }

    &b {
        &-0 {
            margin-bottom: 0 !important;
        }

        &-1 {
            margin-bottom: (1rem * .25) !important;
        }

        &-2 {
            margin-bottom: (2rem * .25) !important;
        }

        &-3 {
            margin-bottom: (3rem * .25) !important;
        }

        &-4 {
            margin-bottom: (4rem * .25) !important;
        }

        &-5 {
            margin-bottom: (5rem * .25) !important;
        }

        &-6 {
            margin-bottom: (6rem * .25) !important;
        }

        &-7 {
            margin-bottom: (7rem * .25) !important;
        }

        &-8 {
            margin-bottom: (8rem * .25) !important;
        }
        &-16 {
            margin-bottom: (16rem * .25) !important;
        }
        &-32 {
            margin-bottom: (32rem * .25) !important;
        }
    }

    &l {
        &-0 {
            margin-left: 0 !important;
        }

        &-1 {
            margin-left: (1rem * .25) !important;
        }

        &-2 {
            margin-left: (2rem * .25) !important;
        }

        &-3 {
            margin-left: (3rem * .25) !important;
        }

        &-4 {
            margin-left: (4rem * .25) !important;
        }

        &-5 {
            margin-left: (5rem * .25) !important;
        }

        &-6 {
            margin-left: (6rem * .25) !important;
        }

        &-7 {
            margin-left: (7rem * .25) !important;
        }

        &-8 {
            margin-left: (8rem * .25) !important;
        }
    }

    &r {
        &-0 {
            margin-right: 0 !important;
        }

        &-1 {
            margin-right: (1rem * .25) !important;
        }

        &-2 {
            margin-right: (2rem * .25) !important;
        }

        &-3 {
            margin-right: (3rem * .25) !important;
        }

        &-4 {
            margin-right: (4rem * .25) !important;
        }

        &-5 {
            margin-right: (5rem * .25) !important;
        }

        &-6 {
            margin-right: (6rem * .25) !important;
        }

        &-7 {
            margin-right: (7rem * .25) !important;
        }

        &-8 {
            margin-right: (8rem * .25) !important;
        }
    }

    &x {
        &-0 {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        &-1 {
            margin-left: (1rem * .25) !important;
            margin-right: (1rem * .25) !important;
        }

        &-2 {
            margin-left: (2rem * .25) !important;
            margin-right: (2rem * .25) !important;
        }

        &-3 {
            margin-left: (3rem * .25) !important;
            margin-right: (3rem * .25) !important;
        }

        &-4 {
            margin-left: (4rem * .25) !important;
            margin-right: (4rem * .25) !important;
        }

        &-5 {
            margin-left: (5rem * .25) !important;
            margin-right: (5rem * .25) !important;
        }

        &-6 {
            margin-left: (6rem * .25) !important;
            margin-right: (6rem * .25) !important;
        }

        &-7 {
            margin-left: (7rem * .25) !important;
            margin-right: (7rem * .25) !important;
        }

        &-8 {
            margin-left: (8rem * .25) !important;
            margin-right: (8rem * .25) !important;
        }
    }
}

.mb-0-xl {
    @include media-breakpoint-up(lg) {
        margin-bottom: 0 !important;
    }
}

.mr-auto {
    margin-right: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mt-lg-5 {
    @include media-breakpoint-down(lg) {
        margin-top: (5rem * .25) !important;
    }
}
.amb-14{
    margin-bottom: 1.4rem !important;
}
.amt-16{
    margin-top: 1.6rem !important;
}
.amt-20 {
    margin-top: 2.0rem !important;
}
.amt-24 {
    margin-top: 2.4rem !important;
}

.amr-24 {
    margin-right: 2.4rem !important;
}
.amr-16-lg{
    @include media-breakpoint-down(lg){
        margin-right: 1.6rem !important;
    }
}

.amb-16-lg{
    @include media-breakpoint-down(lg){
        margin-bottom: 1.6rem !important;
    }
}
.aml-24 {
    margin-left: 2.4rem !important;
}

.aml-16-lg{
    @include media-breakpoint-down(lg){
        margin-left: 1.6rem !important;
    }
}

.aml-12 {
    margin-left: 1.2rem !important;
}

.amb {
    &-16 {
        margin-bottom: 1.6rem !important;
    }
    &-24 {
        margin-bottom: 2.4rem !important;
    }
}

.amr-8 {
    margin-right: .8rem !important;
}
.am-16{
    margin: 1.6rem !important;
}
.amr-16{
    margin-right: 1.6rem !important;
}
.aml-16{
    margin-left: 1.6rem !important;
}

.mt-26 {
    margin-top: 26px !important;
}

.am {
    &x {
        &-1 {
            margin-left: .1rem !important;
            margin-right: .1rem !important;
        }
        &-16 {
            margin-left: 1.6rem !important;
            margin-right: 1.6rem !important;
        }
    }
}

/* ==========================================================================
   # END MARGIN ULTILITIES
   ==========================================================================
*/
