.a-form {
    &__item {
        margin-bottom: 0;

        .label-require {
            position: relative;

            &::before {
                position: absolute;
                right: -1rem;
                top: 0;
                display: inline-block;
                content: '*';
                margin-right: 0;
                font: {
                    size: font-size(medium);
                    family: SimSun, sans-serif;
                }
                line-height: 1;
                color: #F5222D;
            }
        }

        .ant-form-item-label {
            margin-bottom: .3rem;
            padding: 0;
            line-height: 2rem;
            @include media-breakpoint-down(lg) {
                line-height: 1.5rem;
                margin-bottom: .7rem;
            }
        }

        &__heading, label {
            font: {
                size: font-size(s-small) !important;
                weight: font-weight(regular);
            }
        }

        .ant-form-item-control {
            line-height: 1.3rem;
        }

        /*Form search trong search danh sách*/
        &.search-content {
            margin-bottom: 1rem;
            @include media-breakpoint-down(lg) {
                margin-bottom: 1.5rem;
            }

            .ant-form-item-label {
                padding-bottom: 0;
                margin-bottom: 0;
                line-height: 1.9;
            }
        }

        /*Form sửa trong sửa kiện*/
        &__edit-package {
            margin-bottom: 2.4rem;
        }

        /*Form lý do lưu kho có input nhập lý do khác*/
        &.reason--save-warehouse {
            .ant-form-item-children {
                @include a-flex;
                width: 100%;
            }
        }
    }

    &--group-button {
        &--warehouse {
            @include a-flex;
            flex-wrap: wrap;
        }

        &--select {
            margin: 0 !important;
        }
    }

    &--group {
        margin-bottom: 2.4rem;

        &.input-rease-warehouse {
            margin-bottom: 0;
            margin-top: 2.3rem;
            @include media-breakpoint-down(lg) {
                margin-top: 1.9rem;
                margin-bottom: 0;
            }
        }
    }

    /*Form trong sidebar sub ở trang đóng bao*/
    &--sider-in-bag-pack {
        .item--bag-pack {
            &:not(:last-child) {
                margin-bottom:1.6rem;
            }

            &.label-special {
                .ant-form-item-label {
                    label {
                        font-size: font-size(medium) !important;
                        color: rgba(color(black), .85);
                        font-weight: font-weight(regular);
                    }
                }
            }

            .item--bag-pack--action {
                @include a-flex;
            }
        }
    }

    &__search-inline-title {
        position: relative;
        margin-left: 2.4rem;
        padding-left: 3.2rem;
        margin-top: -.6rem;
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2rem;
            width: .1rem;
            content: '';
            background-color: rgba(color(black), .45);
        }
    }

    /* Lựa chọn user từ bên trái sang bên phải */
    &--select-double-column {
        @include a-flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .title {
            padding: 1.6rem;
            background-color: color(gray-F3);

            h2 {
                font-size: 1.4rem;
                font-weight: 500;
                color: rgba(color(black), .85);
                text-transform: uppercase;
            }
        }

        &__body {
            @include a-flex;
            flex-direction: row;
            width: 100%;
            height: 100%;

            .block-left {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 35%;
                height: 100%;
                border-right: .1rem solid color(border);

                .body-left {
                    padding: 1.6rem 0 0 1.6rem;
                    height: 85%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
                .list-user {
                    margin-top: 1.1rem;
                    height: 100%;
                    margin-bottom: 0;
                    padding-right: 1.6rem;
                    overflow-y: auto;
                    @include customscrolltrack(.4rem, color(gray-9F), 1.4rem);

                    li {
                        list-style-type: none;
                        @include a-flex;
                        flex-direction: row;
                        align-items: center;

                        &:not(:last-child) {
                            margin-bottom: 1.4rem;
                        }

                        img {
                            margin-left: .8rem;
                            margin-right: .4rem;
                            width: 3.2rem;
                            height: 3.2rem;
                            object-fit: cover;
                            @include border-radius("50%");
                        }

                        .info {
                            width: 100%;
                            overflow: hidden;

                            .info-name {
                                margin-bottom: 0;
                                font: {
                                    size: 1.4rem;
                                    weight: 400;
                                }
                                color: rgba(color(black), .85);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                line-height: 1.9rem;
                            }

                            .info-phone {
                                font: {
                                    size: 1.2rem;
                                    weight: 400;
                                }
                                color: rgba(color(black), .45);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                line-height: 1.6rem;
                            }
                        }
                    }
                }

            }

            .block-middle {
                width: 45%;
                display: flex;
                flex-direction: column;
                border-right: .1rem solid color(border);
                .body-middle {
                    padding: 1.6rem;
                    height: 100%;
                    flex: 1;
                    overflow-y: auto;
                    @include customscrolltrack(.4rem, color(gray-9F), 1.4rem);
                }
            }

            .block-right {
                width: 20%;
                display: flex;
                flex-direction: column;

                .body-right {
                    padding: 1.6rem;
                    height: 100%;
                    flex: 1;
                    overflow-y: auto;
                    @include customscrolltrack(.4rem, color(gray-9F), 1.4rem);
                }

                .list-agency {
                    margin-top: 1.1rem;
                    height: 100%;
                    margin-bottom: 0;
                    padding-right: 1.6rem;
                    overflow-y: auto;
                    @include customscrolltrack(.4rem, color(gray-9F), 1.4rem);

                    li {
                        list-style-type: none;
                        @include a-flex;
                        flex-direction: row;
                        align-items: center;

                        &:not(:last-child) {
                            margin-bottom: 1.4rem;
                        }
                        .info {
                            width: 100%;
                            overflow: hidden;

                            .info-name {
                                margin-bottom: 0;
                                font: {
                                    size: 1.4rem;
                                    weight: 400;
                                }
                                color: rgba(color(black), .85);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                line-height: 1.9rem;
                            }
                        }
                    }
                }
            }


        }

        &__footer {
            padding: 1.6rem;
            @include a-flex;
            justify-content: flex-end;
            border-top: .1rem solid color(border);
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;

        &__label {
            font-size: 12px;
            color: rgba(0, 0, 0, .45);
            line-height: 1.6rem;
            margin-bottom: 2px;
        }

        &__value {
            font-size: 14px;
            color: #262626;
            line-height: 2.2rem;

            &.link {
                color: color(primary);
            }
        }
    }
}


/*Xóa kích thước trong thông số kiện hàng*/
.link-reload {
    i {
        font-size: font-size(medium);
        color: color(primary);
        margin-right: .3rem;
    }

    font: {
        size: font-size(s-small);
    }
    color: color(primary);

    &:hover {
        opacity: .95;
    }

}

/*Thông báo lỗi trong form*/
.ant-form-explain {
    margin-top: .2rem;
    font: {
        size: font-size(s-small);
    }
}

/*Danh sách đặc tính của kiện*/
.property--package {
    @include a-flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
    padding-top: .5rem;

    .list {
        @include a-flex;
        list-style-type: none;
        margin-bottom: .5rem;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }

        &.active {
            transition: ease all .3s;

            &:hover {
                color: color(white);
            }

            .link {
                background-color: color(primary);
                color: color(white);

                &:focus {
                    color: color(white);
                }

                &:hover {
                    color: color(white) !important;
                }

                .anticon svg {
                    path, circle, rect {
                        fill: color(white);
                    }
                }
            }
        }

        .link {
            padding: .5rem 1rem;
            line-height: 1.8rem;
            color: rgba(color(black), .85);
            @include border-radius(.3rem);

            &:focus {
                text-decoration: none;
            }

            &:hover {
                color: rgba(color(black), .45);
            }
        }
    }
}

.a-group {
    &--input-inline {
        @include a-flex;

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.divided_conversion .ant-form-item-label > label {
    display: flex;
}
