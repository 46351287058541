.a-block-config {
  width: 100%;
  background-color: white;
  @include border-radius(.4rem);

  &__title {
    padding: 1.6rem 2.4rem;
    border-bottom: .1rem solid color(border);
    @include media-breakpoint-down(lg) {
      padding: 1.6rem 1.6rem;
    }

    .title {
      font: {
        size: font-size(large);
        weight: bold;
      };
      line-height: 2.2rem;
    }

    &--block-child {
      padding-bottom: 2px;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, .25);

      .title {
        color: rgba(0, 0, 0, .85);
        font-weight: 500;
      }
    }
  }

  &__body {
    padding-top: 1.6rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    @include media-breakpoint-down(lg) {
      padding-top: 1.6rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    .ant-upload {
      width: 100%;
    }

    .partner-image-container {
      width: 90px;
      height: 90px;
      float: left;
      position: relative;
      margin-top: 2.4rem;

      .image-partner {
        width: 90px;
        height: 90px;
      }

      .close-image-partner {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
    }

  }

}

.a-content-config {
  width: calc(100% - #{ get-map($widths, "menu-config", "xxl")} - 4.8rem);
  @include media-breakpoint-down(lg) {
    width: calc(100% - #{ get-map($widths, "menu-config", "lg")} - 3.2rem);
  }
}

.a-content-manifest {
  width: 100%
}
