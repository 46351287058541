.a-content--page-list{
    .logo-shop{
        display: inline-flex;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: .7rem;
        object-fit: cover;
        @include border-radius(.2rem);
    }
}
.logo-shop{
    display: inline-flex;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: .7rem;
    object-fit: cover;
    @include border-radius(.2rem);
}