/* ==========================================================================
   # START INLINE-EDIT COMPONENT
   ==========================================================================
*/
.a-inline-edit{
    @include a-flex;
    flex-wrap: wrap;
    &--label{
        width: 100%;
        font:{
            size: font-size(s-small);
            weight: font-weight(regular);
        }
        line-height: 1.4rem;
        color: rgba(color(black),.45);
        .icon-inline{
            margin-left: .5rem;
            animation: opacity-shadow .3s linear;
            font:{
                size: font-size(medium);
            }
            color: color(primary);
            &:hover{
                cursor: pointer;
            }
        }
    }
    &--error-text{
        margin-top:.3rem;
        font-size: font-size(s-small);
        color: rgba(color(red),1);
        animation: show-error-text .2s linear;
        margin-bottom: 0;
    }
    &--value{
        width: 100%;
        margin-top: .5rem;
        font:{
            size: font-size(medium);
            weight: font-weight(regular);
        }
        line-height: 1.8rem;
        color: rgba(color(black),.85);
        animation: opacity-shadow .3s linear;
    }
    &--input{
        position: relative;
        width: 100%;
        margin-top: .5rem;
        animation: show-input-inline .2s linear;
        .icon-loading{
            position: absolute;
            right: .5rem;
            top: 1rem;
        }
    }
    &.service-view{
        border-left: .1rem solid color(border);
        .a-inline-edit--label{
            padding: 1.2rem 1.2rem 1.2rem 1.4rem;
            font-size: font-size(medium);
            font-weight: font-weight(bold);
            color: rgba(color(black),.85);
            background-color: color(white);
            border-top-left-radius: .4rem;
            border-top-right-radius: .4rem;
        }
        .a-service{
            border:.1rem solid color(white);
            .service--body {
                padding: 0 1.2rem 1.2rem 1.4rem;
                .title--block {
                    font: {
                        size: font-size(s-small);
                        weight: font-weight(regular) !important;
                    }
                    color: rgba(color(black), .45);
                }
                .service--block {
                    border: none !important;
                    padding-left: 0;
                  
                    .service--block--body {
                        margin-top: 0rem;
                        @include media-breakpoint-down(lg){
                            margin-top: 1rem;
                        }
                        ul {
                            li {
                                span {
                                    @include a-flex;
                                    align-items: center;
                                    padding-left: 0;
                                    font: {
                                        size: font-size(s-small);
                                        weight: font-weight(regular);
                                    }
                                    line-height: 2.8rem;
                                    color: rgba(color(black), .85);
                                    img {
                                        width: 2rem;
                                        height: 2rem;
                                        object-fit: cover;
                                        margin-right: 1rem;
                                    }
                                    svg {
                                        width: 2rem;
                                        height: 2rem;
                                        object-fit: cover;
                                        margin-right: 1rem;
                                    }
                                }
                            }

                            &._service_list {
                                li {
                                    span {
                                        svg {
                                            height: 1.6rem;
                                            width: 1.6rem;
                                        }
                                    }
                                }
                            }
                        }
                        .service-empty {
                            font: {
                                size: font-size(medium);
                                weight: font-weight(regular);
                            }
                            color: rgba(color(black), .45);
                        }
                    }
                }
            }
        }
        .a-inline-edit--value{
            margin-top: 0;
        }
    }
    &.service-edit{
        .a-service{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        .a-inline-edit--label{
            padding: 1.2rem 1.2rem 1.2rem 1.4rem;
            font-size: font-size(medium);
            font-weight: font-weight(bold);
            color: rgba(color(black),.85);
            background-color: color(gray-FA);
            border-top-left-radius: .4rem;
            border-top-right-radius: .4rem;
            border: .1rem solid color(border);
            border-bottom: none;
        }
        .a-inline-edit--input{
            margin-top: 0;
        }
    }
}
/* ==========================================================================
   # END INLINE-EDIT COMPONENT
   ==========================================================================
*/