.a-main {
    padding-left: 2.4rem;
    margin-left: width(sider-main-collapsed, lg);
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        padding-left: 1.6rem;
    }
}

.a-content {
    padding-left: 2.4rem;
    @include media-breakpoint-down(lg) {
        padding-left: 1.6rem;
    }

    &__top {
        @include a-flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 2.4rem;
        padding: 1.2rem 2.4rem;
        background-color: color(white);
        @include box-shadow(0, 0, .6rem, .1rem, rgba(color(black), 0.02));
        @include border-radius(.4rem);
        @include media-breakpoint-down(lg) {
            margin-right: 1.6rem;
            padding: 1.2rem 1.6rem;
        }

        &__title {
            h3 {
                margin-bottom: 0;
                font: {
                    size: font-size(large);
                    weight: font-weight(bold);
                }
                color: rgba(color(black), .85);

                span {
                    font: {
                        size: font-size(s-small);
                        weight: font-weight(regular);
                    }

                    > span {
                        color: color(primary);
                    }
                }

                a {
                    font: {
                        weight: font-weight(regular);
                    }
                }
            }
        }

        /*Phần đầu trang danh sách*/
        &--page-list {
            margin-bottom: -1.3rem;
            position: relative;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: .4rem;
            border-top-right-radius: .4rem;
            margin-top: 1.6rem;

            &:before {
                position: absolute;
                top: 100%;
                left: 0;
                content: '';
                width: 100%;
                height: 1px;
                background-color: rgba(color(black), .05);
            }
        }

        &.action-inventory-history {
            //position: absolute;
            //right: 0;
            justify-content: flex-end;
            z-index: 1;
            box-shadow: none;

            &:before {
                height: 0;
            }
        }
    }

    &__body {
        margin-top: 1.4rem;
        padding: 2.4rem .7rem 2.4rem 2.4rem;
        border-radius: .4rem;
        @include media-breakpoint-down(lg) {
            padding: 1.6rem .7rem 1.6rem 1.6rem;
        }

        &.is-pagination-fixed {
            margin-bottom: 4.1rem;
        }

        &.is-sider-left {

        }

        &.bg-white {
            background-color: color(white);
        }

        /*Body trang danh sách*/
        &--page-list {
            margin-right: 2.4rem;
            padding-right: 2.4rem;
            padding-bottom: 2.4rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: .4rem;
            border-bottom-right-radius: .4rem;
            @include media-breakpoint-down(lg) {
                margin-right: 1.6rem;
                padding-right: 1.6rem;
            }

            &.inventory-history {
                margin-top: 0;
            }

            &:hover {
                &::-webkit-scrollbar-thumb {
                    background: color(gray-9F);
                    border-radius: 10px;
                }
            }

            &::-webkit-scrollbar {
                width: .9rem;

                &:horizontal {
                    height: .9rem;
                }
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
                border-radius: 1rem;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: color(gray-9F);
                cursor: pointer;
            }

            .logo-shop {
                display: inline-flex;
                width: 1.6rem;
                height: 1.6rem;
                margin-right: .7rem;
                object-fit: cover;
                @include border-radius(.2rem)
            }
        }
    }

    &.scroll-is-sider {
        margin-left: width('sider', xxl);
        @include media-breakpoint-down(lg) {
            margin-left: width('sider', lg);
        }
    }

    &__block-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
        margin-right: 1.1rem;
        @include css-transition('ease all .4s');
        @include media-breakpoint-down(lg) {
            margin-right: .4rem;
        }

        &:hover {
            &::-webkit-scrollbar-thumb {
                background: color(gray-9F);
                border-radius: 10px;
            }
        }

        &::-webkit-scrollbar {
            width: .9rem;

            &:horizontal {
                height: .9rem;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 1rem;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: color(gray-9F);
            cursor: pointer;
        }
    }

    /*Tìm kiếm đầu trang danh sách*/
    &--search-header {
        margin-right: 2.4rem;
        margin-bottom: 1.6rem;
        padding: 1.2rem 2.4rem 1.6rem  2.4rem;
        background-color: color(white);
        @include border-radius(.4rem);
        @include media-breakpoint-down(lg) {
            margin-right: 1.6rem;
            padding: .8rem 1.6rem 1.6rem  1.6rem;
        }

        &.list-invoice {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &.search-default {
            .search--right {
                @include a-flex;
                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                }

                .item--special {
                    width: 100%;
                }


                &.inventory-detail {
                    position: relative;

                    &:after {
                        position: absolute;
                        content: '';
                        left: 1px;
                        top: -1.6rem;
                        width: 1px;
                        height: calc(100% + 3.2rem);
                        background-color: rgba(color(black), .1);
                    }
                }
            }

            .search--action {
                @include a-flex;
                justify-content: flex-end;
                flex-direction: row;
            }
        }

        .search--left {
            position: relative;

            &:after {
                position: absolute;
                content: '';
                right: 1px;
                top: -1.6rem;
                width: 1px;
                height: calc(100% + 3.2rem);
                background-color: rgba(color(black), .1);
            }

            &.inventory-detail {
                &:after {
                    height: calc(100% + 5.5rem);
                }
            }
        }

        .search--action {
            @include a-flex;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            margin-top: .6rem;
            padding-top: 1.6rem;
            border-top: .1rem dashed rgba(color(black), .25);
            @include media-breakpoint-down(lg){
                margin-top:.2rem;
            }
            a {
                @include media-breakpoint-down(lg) {
                }
            }

            .link-reload {
                margin-right: 1.5rem;
                color: rgba(color(black), .45);

                i {
                    color: rgba(color(black), .65);
                }
            }
        }

        &.search-crosscheck {
            padding: 1.6rem 2.4rem 0 2.4rem;

            .search-crosscheck--title {
                @include a-flex;
                justify-content: space-between;
                padding-bottom: 1.2rem;
                padding-left: 2.4rem;
                padding-right:2.4rem;
                margin-bottom: 1.4rem;
                margin-left: -1.2rem;
                margin-right: -1.2rem;
                border-bottom: 1px solid color(border);

                .title {
                    font: {
                        size: 1.6rem;
                        weight: 500;
                    }
                    color: rgba(color(black), .85) !important;
                    text-transform: uppercase;
                }
            }

            .info--label {
                margin-bottom: .4rem;
            }

            .search--left {
                &:after {
                    width: 0;
                }

                .a-block-info {
                    padding-bottom: 0;

                    .info--group {
                        margin-bottom: 1.6rem;
                    }
                }
            }

            .search--right {
                margin-top: .5rem;

                .search--action {
                    margin-bottom: 0;

                    .a-btn--search-list {
                        padding-left: 3.7rem;
                        padding-right: 3.7rem;
                    }
                }
            }
        }
    }

    /*Trang danh sách*/
    &--page-list {
        padding-left: 0;
        /*Title lish danh sách*/
        &__title {
            h3 {
                margin-bottom: 0;
                font: {
                    size: font-size(large);
                    weight: font-weight(bold);
                }
                color: color(light-black);

                span {
                    font: {
                        size: font-size(s-small);
                        weight: font-weight(medium);
                    };

                    > span {
                        color: color(primary);
                    }
                }
            }
        }
    }

    /*Phần nội dung content có chatbox bên phải*/
    &--ischatbox {
        width: calc(100% - #{ get-map($widths, "chatbox", "lg")} - 4.4rem);
        @include media-breakpoint-down(lg) {
            width: calc(100% - #{ get-map($widths, "chatbox", "sm")} - 3.2rem);
        }

    }

    &--bag {
        margin-right: 2.4rem;
        @include media-breakpoint-down(lg) {
            margin-right: 1.6rem;
        }

        .body {
            padding-bottom: 2.4rem !important;
            @include media-breakpoint-down(lg) {
                padding-bottom: 1.25rem !important;
            }
        }

        &.bag-create {
            .body {
                padding-bottom: 1.6rem !important;
                .a-form--group {
                    margin-bottom: 1.6rem;
                }
                .a-package--action {
                    padding-top: 1.6rem;
                }
            }

        }
    }

    &--scroll-content {
        width: 100%;
        padding-right: calc(#{ get-map($widths, "chatbox", "lg")} + 4.8rem);
        height: calc(100vh - 17rem);
        overflow-y: auto;
        @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
        @include media-breakpoint-down(lg) {
            padding-right: calc(#{ get-map($widths, "chatbox", "sm")} + 2.6rem);
        }

        &.not-navigation-fixed {
            height: calc(100vh - 10.5rem);
        }
    }

    &.is-action-fixed-bottom {
        margin-bottom: 6.7rem;
    }

    &--action-fixed-bottom {
        position: fixed;
        bottom: 0;
        right: 2.4rem;
        @include a-flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        width: calc(100% - #{ get-map($widths, "sider", "xxl")} - #{ get-map($widths, "sider-main-collapsed", "lg")} - 7.2rem);
        height: 6.4rem;
        background-color: color(white);
        box-shadow: 0 0 .6rem rgba(color(black), .16);
        @include media-breakpoint-down(lg) {
            width: calc(100% - #{ get-map($widths, "sider", "lg")} - #{ get-map($widths, "sider-main-collapsed", "lg")} - 4.7rem);
            right: 1.6rem;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
        z-index: 100;
    }

    &--empty {
        @include a-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .description {
            margin-top: 2rem;
            font: {
                size: 2.4rem;
                weight: 400;
            }
            line-height: 3.2rem;
            color: rgba(color(black), .25);
        }

        &.scanpackage {
            img {
                width: 16.2rem;
                height: 16.2rem;
                object-fit: cover;
                margin-bottom: 1.6rem;
            }

            span {
                text-align: center;
                font: {
                    size: font-size(xx-large);
                    weight: font-weight(bold);
                }
                color: rgba(color(black), .25);
                text-transform: uppercase;
                line-height: 3rem;
            }
        }

        &.request-delivery-note {
            width: calc(100% - 2.4rem);
            @include media-breakpoint-down(lg) {
                width: calc(100% - 1.6rem);
            }
        }

        &.inventory-scan {
            margin-right: 2.4rem;
            width: calc(100% - 2.4rem);
            @include media-breakpoint-down(lg) {
                width: calc(100% - 1.6rem);
            }
            .inventory-img-scan {
                width: 24.4rem;
                height: 24.4rem;
            }
            .inventory-title-scan {
                margin-top: 2.4rem;
                margin-bottom: 2.4rem;
                text-transform: uppercase;
                color: rgba(color(black), .45);
                font: {
                    size: 2.4rem;
                    weight: font-weight(medium);
                }
                @include media-breakpoint-down(lg) {
                    margin-top: 1.6rem;
                    margin-bottom: 2.6rem;
                }
            }
        }

        &--inventory-scan {

        }
    }

  &--manifest {
    @include a-flex;
    flex-direction: column;
    width: 100%;


    .title {
      height: 54px;
      margin-top: 100px;
      padding: 1.5rem 2.4rem;
    }

    .a-block-info {
      @include a-flex;
      justify-content: center;
      flex-direction: column;
    }
  }

    &--scroll-is-box-chat {
        width: 100%;
        padding-right: calc(#{ get-map($widths, "chatbox", "lg")} + 4.2rem);
        height: 100%;
        overflow-y: auto;
        @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
        @include media-breakpoint-down(lg) {
            padding-right: calc(#{ get-map($widths, "chatbox", "sm")} + 2.6rem);
        }

        &.request-delivery-note {
            width: auto;
            margin-right: 2.4rem;
            @include media-breakpoint-down(lg) {
                margin-right: 1.6rem;
            }
        }
    }

    &--scroll-is-box-chat {
        width: 100%;
        padding-right: calc(#{ get-map($widths, "chatbox", "lg")} + 4.2rem);
        height: 100%;
        overflow-y: auto;
        @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
        @include media-breakpoint-down(lg) {
            padding-right: calc(#{ get-map($widths, "chatbox", "sm")} + 2.6rem);
        }
    }

    &--height-not-header {
        height: calc(100vh - #{ get-map($heights, "header", "lg")} - 2.4rem);
    }
}

.content-list--empty {
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .info {
        margin-top: 1.5rem;
        font-size: font-size(x-large);
        color: rgba(color(black), .25);
        font-weight: font-weight(bold);
    }
}

/*Block trong chi tiết kiện giống panel */
.block--primary {
    position: relative;
    @include border-radius(.4rem);

    &.check-package {
        .title {
            padding: 1.4rem 2.4rem;
        }
    }

    .title {
        @include a-flex;
        align-items: center;
        padding: 1.5rem 2.4rem;
        color: color(white);
        background-color: color(primary);
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
        height: 54px;
        @include media-breakpoint-down(lg) {
            padding: 1.6rem 1.6rem;
        }

        &.create-bag {
            @include media-breakpoint-down(lg) {
                flex-direction: row;
                align-items: center;
            }

            .right {
                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }
            }
        }

        .left {
            @include media-breakpoint-down(lg) {
                @include a-flex;
                justify-content: space-between;
            }

            span {
                white-space: nowrap;

                &:first-child {
                    @include media-breakpoint-down(lg) {
                        margin-right: 3rem;
                    }
                }
            }

            .block-mr-50 {
                margin-right: 5rem;
            }
        }

        .right {
            margin-left: auto;
            color: color(white);
            font: {
                weight: font-weight(regular);
            }

            a {
                color: white;
            }
        }
    }

    .body {
        padding: 2.4rem 2.4rem 0.2rem 2.4rem;
        background-color: color(white);
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
        @include box-shadow(0, .3rem, .8rem, rgba(color(black), .09));
        @include media-breakpoint-down(lg) {
            padding: 1.25rem 1.25rem 0.2rem 1.25rem;
        }

        &.not-boxshadow {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        .block--group {
            .title--block {
                margin-top: .8rem;
                margin-bottom: 1.5rem;

                h4 {
                    padding-bottom: .5rem;
                    font: {
                        size: font-size(medium);
                        weight: font-weight(bold);
                    }
                    border-bottom: .1rem solid color(gray-E0);
                }
            }
        }
    }
    &.detail-delivery-note {
        margin-bottom: 1.6rem;
        .title {
            padding: 1rem 2.4rem;
            margin-bottom: 1.4rem;
        }
        .body {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            padding-top:0;
            box-shadow: none;
        }
    }
}

/*Block thong tin default*/
.a-block {
    .action-width-fixed {
        @include a-flex;
        justify-content: center;
        align-items: center;
        margin-top: 2.4rem;
    }
}

.block--export-scan {
    @include a-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem !important;
}

/*Block thông tin chi tiết từng thành phần*/
.a-block-info {
    padding-bottom: 1.25rem;
    @include border-radius(.3rem);
    /*Block thong tin khach hang khi man hinh nho hon 1280*/
    &.infocustomer {
        @include media-breakpoint-down(lg) {
            margin-top: 2rem !important;
        }
    }

    &.detail-delivery-note {
        .info--group {
            .info--value {
                font-weight: 700;
            }
        }
        .a-inline-edit--value {
            font-weight: bold;
        }
    }

    &.bg-gray {
        margin-left: 0 !important;
        margin-right: 0 !important;
        border: .1rem solid color(gray-D9);
        background-color: color(gray-FB);
        @include media-breakpoint-down(lg) {
            padding-top: 0;
        }
    }

    &.info--detail {
        .info--label {
            margin-bottom: .4rem !important;
        }

        .info--group {
            margin-bottom: 1.6rem;
        }
    }

    &.block-create-bag {
        margin-left: -1.6rem;
        @include media-breakpoint-down(lg) {
            margin-left: -1.2rem;
        }
        .block-create-bag-title {
            margin-left: 1.6rem;
            border-bottom: .1rem solid color(border);
            padding-bottom: .4rem;
        }
    }

    .info--title {
        margin-top: 1.6rem;
        margin-bottom: 1rem;
        @include media-breakpoint-down(lg) {
            margin-top: 1.6rem;
        }

        &.iscollapse {
            @include a-flex;
            justify-content: space-between;
            position: relative;

            &:before {
                position: absolute;
                top: 1rem;
                left: 2.4rem;
                width: calc(100% - 4.8rem);
                height: .1rem;
                content: '';
                background-color: color(border);
            }

            .title {
                background-color: color(white);
                z-index: 1;
                padding: 0;
                padding-right: .5rem;
            }

            .title--collapse {
                padding-left: .5rem;
                background-color: color(white);
                color: color(primary);
                z-index: 1;

                span {
                    .icon-collapse {
                        margin-left: .5rem;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

        }

        h4 {
            padding-bottom: .5rem;
            font: {
                size: font-size(medium);
                weight: font-weight(bold);
            }
            color: rgba(color(black), .85);
        }
    }

    .info--group {
        margin-bottom: 2.4rem;

        .info--label {
            display: block;
            width: 100%;
            color: rgba(color(black), .45);
            font: {
                size: font-size(s-small) !important;
                weight: font-weight(regular);
            }
            line-height: 2rem;
            @include media-breakpoint-down(lg) {
                line-height: 1.2rem;
            }
        }

        .info--value {
            display: block;
            margin-bottom: 0;
            color: rgba(color(black), .85);
            font: {
                size: font-size(medium) !important;
                weight: font-weight(regular);
            }
            word-break: break-word;
            line-height: 2rem;

            &.value-link {
                color: color(primary) !important;
                line-height: 2rem;
            }

            &.scan-export {
                margin-top: .5rem;
            }
        }

        .info--value--small {
            @extend .info--value;
            font: {
                size: font-size(small) !important;
            };
            line-height: 2rem;
        }
        .info--value--large {
            @extend .info--value;
            font: {
                size: font-size(large) !important;
            };
            line-height: 2rem;
        }
        &.package--block-volumn {
            .info--label {
                margin-top: .2rem;
                margin-bottom: .7rem;
                color: color(gray) !important;
                @include media-breakpoint-down(lg){
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }
        }
    }

    &.info-form-package {
        .info--title {
            margin-top: 1.6rem;
        }
        .a-form--group {
            margin-bottom: 1.6rem;
            &:last-child {
                margin-bottom: .4rem;
            }
        }
    }
}

.info-customer-view {
    padding-bottom: 1.6rem;
    border-bottom: .1rem solid color(gray-E0);

    .info {
        @include a-flex;
        border-right: .1rem solid #D8D8D8;
        padding-right: .5rem;

        .avatar {
            margin-right: .8rem;

            img {
                width: 4rem;
                height: 4rem;
                object-fit: cover;
                @include border-radius(50%);
            }
        }

        .info-detail {
            @include media-breakpoint-down(lg) {
                @include a-flex;
                flex-direction: column;
            }

            .name {
                margin-bottom: 0;
                font: {
                    size: font-size(medium);
                    weight: font-weight(regular);
                }
                color: rgba(color(black), .85);
            }

            .phonenumber {
                font: {
                    size: font-size(small);
                    weight: font-weight(regular);
                }
                line-height: 1.6rem;
                color: rgba(color(black), .45);
                @include media-breakpoint-down(lg) {
                    margin-top: .6rem;
                }
            }

            .address {
                position: relative;
                padding-left: 1.6rem;
                font: {
                    size: font-size(small);
                    weight: font-weight(regular);
                }
                line-height: 1.6rem;
                color: rgba(color(black), .45);
                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                    margin-top: .6rem;
                }

                &:before {
                    position: absolute;
                    top: .2rem;
                    left: .8rem;
                    content: '';
                    width: .1rem;
                    height: 1.4rem;
                    background-color: color(gray-D9);
                    @include media-breakpoint-down(lg) {
                        width: 0;
                    }
                }
            }
        }
    }
}


/*Scroll khi có sidebar bên trái */
.a-scroll--is-sider {
    .a-sider-sub {
        position: fixed;
        @include css-transition('linear all .5s');
        top: 0;
        height: 100vh;
    }

    .a-content {
        margin-left: width('sider', xxl);
        @include media-breakpoint-down(lg) {
            margin-left: width('sider', lg);
        }
    }
}

.icon-code {
    max-width: 1.4rem;
    max-height: 1.4rem;
    display: inline-flex;
    justify-content: center;
    flex-direction: center;
}

.ant-affix {
    z-index: 1024;
    .a-content__top {
        -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
    }
}

.cursor-pointer {
    cursor: pointer;
}

.a-tile {
    background-color: color(white);

    &__header {
        border-bottom: 1px solid color(border);
        padding: 1.2rem 1.6rem;
    }
    &__body {
        padding: 1.2rem 1.6rem;
    }
}
