/* ==========================================================================
   # START HEADER LAYOUT
   ==========================================================================
*/
.a-header {
    //position: fixed;
    //top: 0;
    display: flex;
    width: calc(100% - 2.4rem);
    height: height(header, lg);
    margin-bottom: 2.4rem;
    padding-left: 0;
    padding-right: 0;
    line-height: height(header, lg);
    background: color(light-gray);
    z-index: 99;
    border: {
        bottom: 1px solid rgba(color(black), .14);
    }
    @include media-breakpoint-down(lg) {
        width: calc(100% - 1.6rem);
        margin-bottom: 1.6rem;
    }

    &__left {
        @include a-flex;
        align-items: center;
        justify-content: space-between;

        .title {
            margin-botom: 0 !important;
            font: {
                size: font-size(title);
                weight: font-weight(bold);
            }
            color: rgba(color(black), .85);
            text-transform: uppercase;
        }

        .icon-sound {
            margin-left: .9rem;

            svg {
                margin-bottom: -.8rem;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__right {
        @include a-flex;
        margin-bottom: 0;
        margin-left: auto;

        li {
            position: relative;
            @include a-flex;
            align-items: center;
            padding-left: 3rem;
            list-style-type: none;

            &:not(:last-child) {
                padding-right: 3rem;

                &::before {
                    position: absolute;
                    content: '';
                    top: calc(50% - 1.2rem);
                    right: 0;
                    width: .1rem;
                    height: 2.4rem;
                    background-color: color(light-black);
                    opacity: .2;
                }
            }
        }

        .search {
            .search__icon {
                margin-right: 2rem;
                font: {
                    size: font-size(x-large);
                }
                color: gray(gray-D9)
            }

            .search__title {
                font: {
                    weight: font-weight(medium);
                }
                color: rgba(color(black), .85);
            }
        }
    }
}

.a-list__notification {
    margin-bottom: 0;
    max-height: 40rem;
    overflow-y: auto;
    @include customscrolltrack(.4rem, color(gray-9F), 1.4rem);

    &__item {
        list-style-type: none;
        border-bottom: .1rem solid #E9E9E9;
        background-color: rgba(color(black), .05);

        &.read {
            background-color: color(white) !important;
        }

        .notification__info {
            display: flex;
            justify-content: space-between;
            padding: 1.5rem;

            .notification__info__left {
                @include a-flex;
                padding-right: 1rem;
                font-size: font-size(s-small);

                .avatar {
                    @include a-flex;
                    justify-content: center;
                    align-items: center;
                    width: 3rem;
                    height: 3rem;
                    margin-right: .9rem;
                    @include border-radius(50%);
                    object-fit: cover;
                }
                .detail-info {
                    flex: 1;
                }
            }

            .notification__info__right {
                .time {
                    font-size: font-size(s-small);
                    color: rgba(color(black), .25);
                    font-weight: font-weight(regular);
                    white-space: nowrap;
                }
            }
        }
    }
}

/* ==========================================================================
   # END HEADER LAYOUT
   ==========================================================================
*/