/* ==========================================================================
   # START BUTTON COMPONENT
   ==========================================================================
*/
.a-btn {

    white-space: nowrap;
    font: {
        size: font-size(medium);
        weight: font-weight(regular);
    };

    &:focus {
        border-color: color(primary);
    }

    &:hover {
        border-color: color(primary);
    }

    &--border-left {
        border-radius: unset !important;
        border-left: .1rem solid color(gray-9F) !important;
    }

    /*Button chính*/
    &--primary {
        color: color(white);
        background-color: color(primary);
        border: .1rem solid color(primary);
        @include css-transition('ease all .4s');

        &:focus {
            border-color: color(primary);
            background-color: color(primary);
            color: white;

            &::before {
                animation-name: opacity-shadow;
                animation-delay: .5s;
            }
        }

        &:hover {
            border-color: color(primary-hover);
            background-color: color(primary-hover);
            color: color(white);

            &::before {
                animation-name: opacity-shadow;
                animation-delay: .5s;
            }
        }

        &:before {
            @include css-transition('ease all .4s');
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            width: .2rem;
            height: 100%;
            background-color: color(primary);
            opacity: 0;
        }

        &.ant-btn-primary[disabled] {
            background-color: color(primary);
            color: color(white);
            opacity: .7;
        }

        &.primary-special {
            &:disabled {
                background-color: color(primary);
                color: white;
                border: .1rem solid color(primary);
                opacity: .7;
                &:hover {
                    background-color: color(primary);
                    color: white;
                    opacity: .7;
                }
            }
        }

        &.ant-btn-background-ghost {
            border: .1rem solid color(primary) !important;
            color: color(primary) !important;

            &:disabled {
                color: rgba(0, 0, 0, 0.25) !important;
                background-color: #f5f5f5 !important;
                border: 1px solid #d9d9d9 !important;

            }
        }
    }

    /*End*/
    &--primary-second {
        color: color(white);
        background-color: color(primary-second);
        border: .1rem solid color(primary-second);
        @include css-transition('ease all .4s');

        &:focus {
            border-color: color(primary-second);
            background-color: color(primary-second);
            color: white;

            &::before {
                animation-name: opacity-shadow;
                animation-delay: .5s;
            }
        }

        &:hover {
            border-color: color(primary-hover);
            background-color: color(primary-hover);
            color: color(white);

            &::before {
                animation-name: opacity-shadow;
                animation-delay: .5s;
            }
        }

        &:before {
            @include css-transition('ease all .4s');
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            width: .2rem;
            height: 100%;
            background-color: color(primary-second);
            opacity: 0;
        }

    }
    /*Button mặc định*/
    &--default {
        color: rgba(color(black), .45);
        background-color: color(white);
        border: .1rem solid color(gray-D9);
        @include css-transition('ease all .4s');

        &:hover {
            color: color(primary);
            background-color: color(white);
        }

        &.active {
            @include css-transition('ease all .4s');
            color: color(white);
            background-color: color(primary);
            border: .1rem solid color(primary);

            &:hover {
                color: color(white);
            }
        }
        &:focus{
            color: color(primary);
            background-color: color(white);
        }

        &:disabled {
            color: rgba(0, 0, 0, 0.25);
            background-color: #f5f5f5;
            border-color: #d9d9d9;

            &:hover {
                color: rgba(0, 0, 0, 0.25);
                background-color: #f5f5f5;
                border-color: #d9d9d9;
            }
        }
    }

    /*End*/
    /*Button full chiều dài*/
    &--w-100 {
        width: 100%;
    }

    /*End*/
    &--h-25 {
        height: 2.5rem;
    }

    &--create-package {
        width: 100%;
        margin-top: 2rem;
    }

    /*Button thêm mới đơn*/
    &--create-bill-code {
        @include a-flex;
        justify-content: center;
        align-items: center;
        width: 18rem;
        height: 4rem;
    }

    /*End*/
    /*Button tạo kiên hoặc chỉnh sửa kiện*/
    &--submit-bill-code {
        @include a-flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
    }

    /*End*/
    /*Button là link*/
    &--button-link {
        height: inherit;
        padding: 0 0;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:focus {
            span {
                text-decoration: underline;
            }
        }

        &:after {
            -webkit-animation: none;
            -o-animation: none;
            animation: none;
        }

        &:disabled {
            color: rgba(color(black), .2) !important;
            background-color: transparent;

            &:hover {
                color: rgba(color(black), .2) !important;
                background-color: transparent;
            }
        }
    }

    /*End*/
    &--font-small {
        font: {
            size: font-size(s-small);;
        }
    }

    &--warehouse {
        margin-bottom: .8rem;
        margin-left: .5rem;
        margin-right: .5rem;
        padding: .6rem .9rem .7rem 1.1rem;
        @include border-radius(.4rem);

    }

    /*Button tạo kiện cùng vận đơn*/
    &--create-package-same-bill-code {
        height: 4rem;
        padding-left: 5rem;
        padding-right: 5rem;
        font: {
            size: font-size(meidum);
            weight: font-weight(regular);
        };
    }

    /*End*/
    /*Disable button*/
    &--disable {
        pointer-events: none;
        color: rgba(color(black), .25) !important;
        background-color: rgba(color(black), .04) !important;
        border: .1rem solid color(gray-D9) !important;
        text-shadow: none;
        font: {
            weight: font-weight(regular);
        }
    }

    /*End*/
    /*Button chọn trọng sider tạo kiện*/
    &--action-sider {
        position: relative;
        @include a-flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 4rem;
        margin-bottom: 1.6rem;
        padding-left: 1.6rem;
        border: {
            top: .1rem solid rgba(color(black), .1);
            bottom: .1rem solid rgba(color(black), .1);
        };
        font: {
            size: font-size(large);
            weight: font-weight(medium);
        }
        color: rgba(color(black), .85);
        @include border-radius(none);
        @include css-transition('ease all .4s');

        &:before {
            @include css-transition('ease all .4s');
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            width: .2rem;
            height: 100%;
            background-color: color(primary);
            opacity: 0;
        }

        &.top-40 {
            margin-top: 4rem;
        }

        &.active {
            font: {
                weight: font-weight(bold);
            }
            background-color: color(blue-F0);
            border: none;

            &:before {
                opacity: 1;
            }
        }

        &:hover {
            border: none;
            font: {
                weight: font-weight(bold);
            }
            background-color: color(blue-F0);
            cursor: pointer;

            &::before {
                opacity: 1;
            }
        }
    }

    /*End*/
    /*Button Lưu khi sửa package*/
    &--save-package {
        height: 3.2rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    /*Button trở lại khi sửa package*/
    &--back-package {
        @include a-flex;
        justify-content: center;
        align-items: center;
        height: 3.2rem;
        margin-right: 2.2rem;
    }

    /*Button Gửi trong chatbox*/
    &--submit-chatbox {
        height: 2.5rem;
        margin-left: -1rem;
        @include border-radius(.2rem);
    }

    /*Button background transparent border white*/
    &--transparent-white {
        border: .1rem solid color(white);

        &:hover {
            transition: ease all .5s;
            color: color(white);
            opacity: .7;
            border: .1rem solid rgba(255, 255, 255, .7);
        }

        &:focus {
            color: color(white);
            animation: opacity-shadow7 1s linear;
            border: .1rem solid rgba(255, 255, 255, .7);
        }
    }

    /*Button upload file trong forom chat*/
    &--chat-upload {
        position: absolute;
        right: 7.8rem;
        top: 1.9rem;

        button[type="button"] {
            border: none;
            padding: 0;
            height: auto;
        }

        .ant-upload-list {
            position: absolute;
            top: 2.5rem;
            right: .5rem;
            max-width: 12rem;
            z-index: 10;
            background-color: color(white);

            .ant-upload-list-item {
                background-color: color(white);
            }
        }
    }

    &--upload {
        button[type="button"] {
            border: none;
            padding: 0;
            height: auto;
        }
    }

    &--search-list {
        width: 20rem;
        font:{
            size:font-size(s-small);
            weight:400;
        }
        text:{
            transform: uppercase;
        }
        @include media-breakpoint-down(lg) {
            width: 18rem;
        }
    }

    /*Button Xem lịch sử, khiếu nại người bán trong chi tiết kiện*/
    &--history-in-package, &--complaint-seller {
        @include a-flex;
        justify-content: center;
        align-items: center;
        height: 3.2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        @include border-radius(.4rem);

        &:hover {
            text-decoration: none !important;
        }

        &:focus {
            text-decoration: none !important;
        }

    }

    /*Button save fixed width */
    &--width-fixed {
        width: 28rem;
        height: 4rem;
    }

    /*Button trong sider-sub tạo bao*/
    &--action-bag-pack {
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 4rem;

        &.disabled-bag-pack {
            background-color: color(gray-E0);
            border: .1rem solid color(gray-E0);
            color: color(gray-9F);
            pointer-events: none;
        }
    }

    &--link {
        border: none;
    }

    &--link-primary {
        padding: .45rem 1.5rem;
        background-color: color(primary);
        color: white;
        -webkit-border-radius: .4rem;
        -moz-border-radius: .4rem;
        border-radius: .4rem;

        &:focus {
            outline: none;
            background: color(primary);
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
            color: color(white);
        }
    }

    &--scan-export {
        width: 28rem;
        height: 4rem;
    }

    &--save-export {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    &--w-70 {
        width: 7rem;
    }

    &--create-independence-package {
        position: absolute;
        bottom: 2.4rem;
        left: 2.4rem;
        width: calc(100% - 4.8rem);
    }

    // Hotfix hiên thị button Tạo kiện độc lập khi cài công cụ
    button.ant-btn.a-btn.a-btn--primary.a-btn--create-independence-package._create-independence-package.hidden.ant-btn-primary {
        display: block !important;
    }

    &--icon-svg {
        &.active {
            path {
                fill: color(primary) !important;
            }
        }
    }

    &--inventory-scan-warehouse {
        width: 20rem;
        text-transform: uppercase;
        margin-top: 2.4rem;
        @include media-breakpoint-down(lg) {
            margin-top: 1.6rem;
        }
    }

    &--danger {
        border: .1rem solid rgba(color(red),1);
        &:hover {
            border: .1rem solid rgba(color(red),1);
        }
        &:focus {
            border: .1rem solid rgba(color(red),1);
        }

        &:disabled {
            color: rgba(0, 0, 0, 0.25) !important;
            background-color: #f5f5f5;
            border-color: #d9d9d9;

            &:hover {
                color: rgba(0, 0, 0, 0.25) !important;
                background-color: #f5f5f5;
                border-color: #d9d9d9;
            }
        }
    }

    &--bg-transparent {
        background-color: transparent;

        &:hover {
            background-color: transparent
        }
    }

    &--split-package {
        border: 1px solid color(primary);
        color: color(primary);
        &:hover {
            border: 1px solid color(primary);
        }
    }
}
.ant-switch-small{
    min-width: 3rem !important;
}
.ant-switch-checked{
    background-color: color(primary) !important;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-left: 4px;
}

/* ==========================================================================
   # END BUTTON COMPONENT
   ==========================================================================
*/

// Hotfix hiên thị button Tạo kiện độc lập khi cài công cụ
button.ant-btn.a-btn.a-btn--primary.a-btn--create-independence-package._create-independence-package.hidden.ant-btn-primary {
    display: block !important;
}
