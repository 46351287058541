/* ==========================================================================
   # START DEFAULT SETTING
   ==========================================================================
*/
* {
    outline: 0;
    margin: 0;
    padding: 0;
}
html {
    font-size: 62.5%;
}
body {
    overflow-x: hidden;
    word-break: break-word;
    font-family: 'Roboto', sans-serif;
    background-color: color(light-gray);
    &::-webkit-scrollbar {
        width: .6rem;
        &:horizontal {
            height: .6rem;
        }
    }
    &::-webkit-scrollbar-track {
        background: color(white);
    }

    &::-webkit-scrollbar-thumb {
        background: color(gray-9F);
        border-radius: 1.4rem;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: color(gray-9F);
        cursor: pointer;
    }
    @include media-breakpoint-up(xxk) {
        max-width: 2560px;
        margin: 0 auto;
    }
}
a {
    color: color(primary);
    @include css-transition('all, 0.3s, ease-in-out');
    &:hover {
        @include css-transition('all, 0.3s, ease-in-out');
        text-decoration: inherit;
        color: color(primary);
        opacity: .85;
    }
    &:focus {
        outline: none;
    }
}
     a {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
h1, h2, h3, h4, h5, h6{
    margin-bottom: 0;
}
/* ==========================================================================
   # END DEFAULT SETTING
   ==========================================================================
*/