.pointer {
    cursor: pointer;
}

.rostate-45 {
    transform: rotate(45deg);
}

.not-allowed {
    cursor: not-allowed;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.dpl-inline-block {
    display: inline-block;
}

.dpl-inline-flex {
    display: inline-flex !important;
}

.dpl-block {
    display: block !important;
}

.width-100-pc {
    width: 100% !important;
}

.width-80-pc {
    width: 75%;
}

.width-75-pc {
    width: 75%;
}

.width-70-pc {
    width: 70%;
}

.width-65-pc {
    width: 65%;
}

.width-60-pc {
    width: 60%;
}

.width-55-pc {
    width: 55%;
}

.width-50-pc {
    width: 50%;
}

.width-45-pc {
    width: 45%;
}

.width-40-pc {
    width: 40%;
}

.width-35-pc {
    width: 35%;
}

.width-30-pc {
    width: 30%;
}

.width-33-pc {
    width: calc(100% / 3);
}

.width-25-pc {
    width: 25%;
}

.width-20-pc {
    width: 20%;
}

.width-15-pc {
    width: 15%;
}

.bg-color-white {
    background-color: white;
}

.min-h-0 {
    min-height: 0;
}

.min-w-0 {
    min-width: 0;
}
.a-sider-sub.bag-pack .info-bag ul {
    padding-bottom: 36px;
}

@media screen and (max-height: 950px) {
    .a-sider-sub.bag-pack .info-bag.scroll {
        height: 400px;
    }
}

@media screen and (max-height: 800px) {
    .a-sider-sub.bag-pack .info-bag.scroll {
        height: 320px;
    }
}

@media only screen and (min-width: 1440px) {
    .a-sider-sub.bag-pack .info-bag.scroll {
        height: 450px;
    }
}

@media only screen and (min-width: 1920px) {
    .a-sider-sub.bag-pack .info-bag.scroll {
        height: 500px;
    }
}

@media screen and (max-height: 700px) {
    .a-sider-sub.bag-pack .info-bag.scroll {
        height: 178px;
    }
}

.a-chatbox-height {
    height: calc(100% - 14px);
}

.clear-fix {
    clear: both;
}
.top-13px {
    top: 13px;
}

.ant-alert-notice {
    background-color: color(papaya-whip);
    border: 1px solid color(orange-FFE);
}

.custom-tab.ant-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 2.4rem;

    .ant-tabs-bar {
        margin-right: 0;
    }
    .ant-tabs-content {
        background-color: color(white);
        flex-grow: 1;
        min-height: 0;
    }
}

.h-100vh {
    height: 100vh !important;
}

.overflow-auto {
    overflow: auto !important;
}

.pointer-events-none {
    pointer-events: none !important;
}

.opacity-5 {
    opacity: 0.5;
}

.d-inline {
    display: inline !important;
}

.bottom-action-list {
    @include a-flex;
    align-items: center;
    margin-bottom: 0;
    li {
        position: relative;
        @include a-flex;
        align-items: center;
        padding-right: 1.5rem;
        list-style-type: none;
        &.not-line-space {
            &:before {
                width: 0 !important;
            }
        }
        &:not(:first-child) {
            padding-left: 1.5rem;
        }
        &:not(:last-child) {
            &:before {
                position: absolute;
                right: 0;
                top: 15%;
                content: '';
                width: 0.1rem;
                height: 70%;
                background-color: rgba(color(black), 0.45);
            }
        }
        &:last-child {
            padding-right: 0;
        }
        @include media-breakpoint-down(lg) {
            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.scroll-small {
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;

        &:horizontal {
            height: 6px;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(185, 183, 183);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgb(185, 183, 183);
        cursor: pointer;
    }
}

.tbody_not_hover_body tr {
    &:hover {
        .warning {
            tr {
                background-color: var(--color-orange-FFF);
            }
        }
        td {
            background-color: unset !important;
        }
    }
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}
.order-5 {
    order: 5;
}

.ellipsis-3 {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 3.6em;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
}
.ellipsis-3 + .ellipsis-3 {
    margin-top: 10px;
}
.ellipsis-3:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
}
.ellipsis-3:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
}

tr.ant-table-row-hover .ellipsis-3:after {
    background: #e9f7fe !important;
}
