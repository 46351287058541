
#page-content {
    display: block;
  }
  
  #print-content {
    display: none;
  }
  
  @media print {
    html, body {
      width: auto !important;
      height: auto !important;
      color: black !important;
    }
    #page-content {
      display: none;
    }
    #print-content {
      display: block;
    }
  
    .a-stamp--print-page-break {
      page-break-before: always;
    }
    .ant-notification {
      display: none;
    }
  }
  
  .a-stamp {
    display: inline-block;
    width: 280px;
    font-size: 16px;
    margin: 0 10px 0;
    padding-top: 10px;
    color: black;
  
    &--print {
      color: black;
  
      &--top {
        @include a-flex;
        justify-content: space-between;
        align-items: center;
      }
  
      &--bottom {
        @include a-flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
  
      &--block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        line-height: 14px;
  
        .username {
          text-transform: uppercase;
          font-size: font-size(small);
          line-height: 14px;
          color: black;
  
        }
  
        .weight {
          font-size: font-size(small);
          line-height: 14px;
          color: black;
        }
  
        .bill-code {
          text-transform: uppercase;
          font-size: font-size(small);
          line-height: 14px;
          color: black;
  
        }
  
        .volumn, .num-package {
          font-size: font-size(small);
          line-height: 14px;
          color: black;
  
        }
      }
    }
  
    &--warehouse {
      text-transform: uppercase;
      font-size: font-size(small);
      line-height: 14px;
      color: black;
  
    }
  
    &--code {
      font: {
        size: font-size(large);
        weight: font-weight(bold);
      }
      text-transform: uppercase;
      color: black;
      line-height: 16px;
  
    }
  
    &--barcode {
      display: flex;
      align-items: center;
    }
  
    &--agency {
      font-size: 18px;
      color: black;
      margin-bottom: 0;
      line-height: 20px;
    }
  }
  
  .print-package-official-stamp {
    padding-top: 15px;
  }
  
  .print-package-official-stamp, .print-package-unofficial-stamp {
    border: 1px solid #333;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: auto;
    width: 530px;
  
    &.print-package-official-stamp-custom {
      width: 510px;
      margin: 5px auto;
  
      .order-no {
        width: 840pt;
      }
    }
  
    .ant-typography {
      color: rgba(var(--color-black), 1);
    }
  
    .partner-logo {
      height: 52px;
      object-fit: cover;
      width: 219px;
    }
  
    .row {
      display: flex;
    }
  
    .tile {
      padding: .5rem;
  
      &:not(:first-child) {
        border-left: 1px solid #000;
      }
    }
  
    .shipment-detail, .product-detail {
      border-bottom: 1px solid #000;
      font-size: 13px;
  
      .row {
        &:not(:last-child) {
          border-bottom: 1px solid #000;
        }
      }
    }
  
    .border-bottom {
      border-bottom: 1px solid #000;
    }
  }
  
  .print-bag-stamp {
    border: 1px solid #333;
    font-size: font-size(small);
    margin: auto;
    padding-top: 15px;
    width: 530px;
    .border-b{
      border-bottom: 1px solid #000;
    }
  
    .ant-typography {
      color: rgba(var(--color-black), 1);
    }
  
    .partner-logo {
      height: 40px;
      width: 40px;
    }
  
    .partner-name {
      line-height: 1;
    }
  
    .partner-mobile {
      .anticon {
        transform: rotateZ(90deg);
      }
    }
  
    .row {
      display: flex;
    }
  
    .tile {
      padding: .5rem;
      &-bl{
          border-left: 1px solid #000;
      }
      &:not(:first-child) {
        border-left: 1px dashed #000;
      }
    }
  
    .shipment-detail, .product-detail {
      border-bottom: 1px solid #000;
  
      .row {
        &:not(:last-child) {
          border-bottom: 1px dashed #000;
        }
      }
    }
  }
  
  .bag-stamp-print {
    .a-stamp--agency {
      font-size: 15px;
    }
  }
  
  .batch-stamp-content {
    padding: 10px 0;
    table {
      width: 100%;
      tr {
        td {
          vertical-align: middle;
          border: 1px solid;
          padding: 10px;
          &:first-child {
            font-weight: 500;
            border-left: unset;
            width: 20%
          }
          &:last-child {
            border-right: unset;
            width: 30%
          }
        }
      }
    }
  }
  

.partner-logo-2 {
  height: 52px;
  object-fit: cover;
  width: 154px;
}