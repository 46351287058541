.gflex {
    @include a-flex;
}

.gjustify-center {
    justify-content: center !important;
}

.gjustify-space-between {
    justify-content: space-between !important;
}

.gjustify-end {
    justify-content: flex-end !important;
}
.gjustify-start {
    justify-content: flex-start !important;
}

.galign-center {
    align-items: center !important;
}

.gflex-direction-column {
    flex-direction: column !important;
}

.gflex-direction-column-reverse {
    flex-direction: column-reverse !important;
}

.galign-baseline {
    align-items: baseline !important;
}

.g-justify-center {
    justify-content: center !important;
}

.g-justify-around {
    justify-content: space-around !important;
}

.galign-end {
    align-items: flex-end !important;
}

.galign-self-end {
    align-self: flex-end !important;
}

.galign-self-center {
    align-self: center !important;
}

.gw-100 {
    width: 100%;
}

.a-flex {
    @include a-flex;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.a-flex-1 {
    flex: 1;
}
.abg-white {
    background-color: color(white) !important;
}

.asticky-top {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.a-zindex {
    &--10 {
        z-index: 10;
    }
}

.atext-right {
    text-align: right;
}

.w-130 {
    width: 130px !important;
}

.w-200 {
    width: 20rem !important;
}
.w-20 {
    width: 2rem !important;
}

.height-not-header {
    height: calc(100vh - #{get-map($heights, 'header', 'lg')} - 2.4rem);
}

.min-height-not-header {
    min-height: calc(100vh - #{get-map($heights, 'header', 'lg')} - 2.4rem);
}

.height-100 {
    height: 100%;
}

.a-bgwhite {
    background-color: color(white);
}

.w100-24 {
    width: calc(100% - 2.4rem);
    @include media-breakpoint-down(lg) {
        width: calc(100% - 1.6rem);
    }
}

.aposition-reletive {
    position: relative !important;
}

.aflex-1 {
    flex: 1;
}

.aheight-100 {
    height: 100%;
}
.aheight-75 {
    height: 75%;
}

.aoverflow-y {
    overflow-y: auto;
    @include customscrolltrack(0.6rem, color(gray-9F), 1.4rem);
}

.aflex-direction-row {
    flex-direction: row !important;
}

.aoverflow-x-auto {
    overflow-x: auto !important;
}
.ahover-pointer {
    &:hover {
        cursor: pointer;
    }
}
.d-webkitbox {
    display: -webkit-box !important;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.hpx-200 {
    height: 20rem !important;
}

.mhpx-200 {
    max-height: 20rem !important;
}
