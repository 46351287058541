.a-dropdown {
    &__choose-language {
        &__title {
            height: 3rem;
            display: flex;
            align-items: center;
            color: color(white);

            &:hover {
                cursor: pointer;
                @include css-transition('ease, all, .5s');

                .icon-dropdown__language {
                    @include css-transition('ease, all, .5s');
                    opacity: .6;
                }
            }

            .icon-dropdown__language {
                margin-left: .5rem;
                color: rgba(color(black), .45);
                font: {
                    size: font-size(s-small);
                }
                opacity: .5;
            }
        }

        .img__flag-language {
            width: 3.2rem;
            height: 2rem;
            @include border-radius(.4rem);
        }

        &--overlay {

            .img__flag-language {
                width: 3.2rem;
                height: 2rem;
                @include border-radius(.4rem);
                margin-right: 1.6rem;
            }

            .ant-dropdown-link {
                padding-left: .6rem;
                padding-right: .6rem;
            }

            .list-language {
                margin-bottom: 0;

                .action-item {
                    list-style-type: none;

                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    &__info-user {
        @include a-flex;
        align-items: center;
        height: 2.5rem;
        overflow: hidden;

        &:hover {
            cursor: pointer;

            .name__user {
                opacity: .5;
                @include css-transition('ease, all, .5s');
            }

            .icon-dropdown__info-user {
                opacity: .3;
                @include css-transition('ease, all, .5s');
            }
        }

        .img__avatar {
            @include circle(2.4rem, transparent);
            object-fit: cover;
        }

        .name__user {
            margin-left: 1rem;
            margin-right: .5rem;
            color: rgba(color(black), .85);
            font: {
                size: font-size(small);
                weight: font-weight(regular);
            }
        }

        .icon-dropdown {
            margin-left: .2rem;
            color: rgba(color(black), .45);
            font: {
                size: font-size(s-small);
            }
            opacity: .5;
        }

        &__list-action {
            .action-item {
                list-style-type: none;
                padding: 1.6rem 2.8rem 1.6rem 1.6rem;

                &:not(:last-child) {
                    border-bottom: .1rem solid color(gray);
                }

                .action-item__link {
                    @include a-flex;
                    align-items: center;
                    color: rgba(color(black), .65);
                    font-weight: 400;
                }

                .action-item__icon {
                    margin-right: 1.4rem;
                }
            }
        }
    }

    &--user {
        .ant-dropdown-menu {
            .ant-dropdown-menu-item {
                padding: 0 3rem;
            }
        }
    }

    &--set-field {
        width: 45rem;
        @include media-breakpoint-down(xxl) {
            width: 38.4rem;
        }

        &__menu {
            @include a-flex;
            flex-wrap: wrap;
            margin-top: 1rem;
            padding: 2.4rem 2.4rem 1.2rem 2.4rem;

            .close-field-menu {
                @include a-flex;
                justify-content: flex-end;
                width: 100%;
                margin-bottom: 1.5rem;
            }
        }

        &__item {
            width: 50%;
            margin-bottom: 1.2rem;
            padding: 0;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.ant-select-dropdown {
    z-index: 1000;

    .ant-select-dropdown-menu {
        &::-webkit-scrollbar {
            width: 6px;

            &:horizontal {
                height: 6px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: color(gray-9F);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: color(gray-9F);
            cursor: pointer;
        }
    }
}

/*Action trong user header*/
.list-action-user {
    .ant-popover-inner-content {
        padding: 0;
    }
}
