/* ==========================================================================
   # START VARIABLE SETTING
   ==========================================================================
*/
//Begin variable root

$breakpoints: (
        md: 767px,
        lg: 1280px,
        xl: 1366px,
        xxl: 1600px,
        xxxl: 1920px,
        xxk: 2560px
);

$fonts: (
        s-small:1.2rem,
        small: 1.3rem,
        medium: 1.4rem,
        large: 1.6rem,
        x-large: 1.8rem,
        xx-large: 2.4rem,
        title: 2rem,
        icon-sider: 2.1rem,
);

$font-weights: (
        light:300,
        regular:400,
        medium:500,
        bold:700,
        max-bold:900
);

$colors: (
        primary:#597EF7,
        primary-hover:#40a9ff,
        primary-bold:#2F54EB,
        primary-second:#7997f1,
        shadow-primary:rgba(24, 144, 255, 0.2),
        blue-link:#3E90F7,
        text-primary:#1890FF,
        blue-E6:#E6F7FF,
        white:#FFFFFF,
        light-blue:#E9F7FE,
        blue-F0:#F0F5FF,
        gray:#B4B6B8,
        dark-gray:#595959,
        gray-F5:#F5F5F5,
        gray-E8:#E8E8E8,
        gray-E0:#E0E0E0,
        gray-9A:#9A9A9A,
        gray-70:#707070,
        gray-80:#808080,
        gray-D9:#D9D9D9,
        gray-9F:#9F9F9F,
        gray-FB:#FBFBFB,
        gray-E7:#E7E9EB,
        gray-F3:#F3F3F3,
        gray-AD:#ADAEB0,
        gray-FA:#FAFAFA,
        border:#E9E9E9,
        light-gray:#F0F2F5,
        dark-blue:#031B4E,
        light-black:#0D1A26,
        light-black-rgb:"13, 26, 38",
        black:"0,0,0",
        light-green:#52C41A,
        red:"245, 34, 45",
        red-FF:#FFF1F0,
        orange-FFF:#FFFBE6,
        orange-FFE:#FFE58F,
        orange-FAA:#FAAD14,
        papaya-whip: #FFF1DC,
        text-primary-black:rgba(0,0,0,.85),
);
$widths: (
        sider:(lg:200px, xl:300px, xxl:300px),
        sider-main:(sm:220px, md:220px, lg:220px),
        sider-main-collapsed:(sm:55px, md:55px, lg:55px),
        chatbox:(sm:250px, md:288px, lg:288px),
        menu-config:(lg:200px,xl:200px,xxl:200px)
);
$heights: (
        header:(lg:72px, md:72px),
        footer:70px,
);

$z-indexs: (
        dropdown: 1000,
        sticky: 1020,
        fixed: 1030,
        modal-backdrop: 1040,
        modal: 1050,
        popover: 1060,
        tooltip: 1070,
);
:root {
    /*Begin z-index*/
    @each $name, $z-index in $z-indexs {
        --z-index-#{$name}: #{$z-index};
    }

    /*End z-index*/

    /*Begin color*/
    @each $name, $color in $colors {
        --color-#{$name}: #{$color};
    }
    /*End color*/

    /*Begin width*/
    @each $name, $width in $widths {
        // maps require a second loop
        @if type-of($width) == "map" {

            // each item in sub map
            @each $subname, $sub-width in $width {
                // --z-index-viewport-md
                --width-#{$name}-#{$subname}: #{$sub-width}
            }

            // top-level z-indexs
        } @else if type-of($width) == "number" {

            // --z-index-background
            --width-#{$name}: #{$width};

        }
    }
    /*End width*/

    /*Begin height*/
    @each $name, $height in $heights {
        // maps require a second loop
        @if type-of($height) == "map" {

            // each item in sub map
            @each $subname, $sub-height in $height {
                // --z-index-viewport-md
                --height-#{$name}-#{$subname}: #{$sub-height}
            }

            // top-level z-indexs
        } @else if type-of($height) == "number" {

            // --z-index-background
            --height-#{$name}: #{$height};

        }
    }
    /*End color*/

    /*Begin font-size*/
    @each $name, $font-size in $fonts {
        --font-size-#{$name}: #{$font-size};
    }
    /*End font-size*/
    /*Begin font-weight*/
    @each $name, $font-weight in $font-weights {
        --font-weight-#{$name}: #{$font-weight};
    }
    /*End font-weight*/
}


//End variable root
/* ==========================================================================
   # END VARIABLE SETTING
   ==========================================================================
*/