.screen-split-package {
  .package-list {
    margin-bottom: 0;

    .package-item {
      .header {
        align-items: center;
        background-color: color(primary);
        color: color(white);
        display: flex;
        justify-content: space-between;
        padding: .5rem 1.6rem;
      }

      .ant-form-item {
        margin-bottom: 0;

        .ant-input-number {
          width: 6rem;
        }

        &.ant-row {
          justify-content: center;
          padding: 0;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1em;
      }

        &.package-origin{
            .header {
                background-color: #072ca7;
        
            }
            ._print-batch-stamp{
                background-color: #072ca7;
                border-color: #072ca7;
                &:hover{
                color: #315ff7;
                }
            }
        }

        
        &.package-split{
            .header {
                background-color: #7b99fa;
        
            }


            ._print-batch-stamp{
                background-color: #7b99fa;
                border-color: #7b99fa;
                &:hover{
                    color: #315ff7;
                }
            }

            &.package-created{
                .header {
                    background-color: green;
            
                }
                ._print-batch-stamp{
                    background-color: green;
                    border-color: green;
                    &:hover{
                        color: #315ff7;
                    }
                }
            }

            &.package-focus{

                .header {
                    background-color: #e9e916;
                    color: #000;
            
                }
                ._print-batch-stamp{
                    background-color: #e9e916;
                    border-color: #e9e916;
                    &:hover{
                        color: #696913;
                    }
                }
                .ant-form.ant-form-horizontal{
                    box-shadow: rgba(136, 165, 191, 0.48)  0px 4px 16px, rgba(136, 165, 191, 0.48)  0px 8px 24px, rgba(136, 165, 191, 0.48)  0px 16px 56px;

                }
            }
        }
    }
  }

  .product-list {
    .product-item {
      .ant-row {
        background-color: color(white);
        display: flex;
        margin: 0 !important;
        padding: 1.6rem;

        .ant-col {
          &:first-child {
            align-self: center;
            font-size: var(--font-size-s-small);

            img {
              height: 6rem;
              object-fit: cover;
              width: 6rem;
            }
          }
        }

        .product-quantity {
          .ant-input {
            text-align: center;
          }
        }

        .ant-col:nth-child(3), .ant-col:nth-child(4), .ant-col:nth-child(5) {
          text-align: center;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid color(border);
      }
    }
  }
}