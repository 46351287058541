.inventory-process {
    .a-tabs {
        &--container {
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-right: 2.4rem;

            .ant-tabs-bar {
                margin-right: 0;
            }
            .ant-tabs-content {
                background-color: color(white);
                flex-grow: 1;
                min-height: 0;
                overflow: auto;
                padding: 2.4rem;

                @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
            }
        }
    }
}
