.upload-damaged-images {
    overflow: auto;
    height: 100vh;
    background-color: color(white);

    .scrollbar-container {
        padding: 3.2rem;
    }

    @include media-breakpoint-down(md) {
        .ant-upload-picture-card-wrapper {
            flex-direction: column-reverse;
            display: flex;
        }
        .ant-upload-list {
            flex-direction: column;
            display: flex;
    
            .ant-upload-list-item {
                width: 100%;
            }
        }
    }
}
