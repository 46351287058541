.a-tag {
    &--user {
        padding: .5rem 1.2rem;
        height: auto;
        margin-bottom: .8rem;
        background-color:color(white);
        border: .1rem solid color(primary);
        @include border-radius("20px");
        font:{
            size: 1.2rem;
            weight: 400;
        }
        color: color(primary);
        i{
            color: color(primary) !important;
        }
    }
}
