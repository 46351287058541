.border {
    &--top {
        border-top: .1px solid color(gray-E0);
    }
    &--bottom {
        border-bottom: .1px solid color(gray-E0);
    }
    &--right {
        border-right: .1px solid color(gray-E0);
    }
    &--none {
        border: none !important;
    }
}
.aradius {
    &--0 {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
    }
    &--2 {
        -webkit-border-radius: .2rem !important;
        -moz-border-radius: .2rem !important;
        border-radius: .2rem !important;
    }
}

.border {
    &-bottom {
        border-bottom: 1px solid rgba(color(black), .14);
    }
    &-right {
        border-right: 1px solid rgba(color(black), .14);
    }
    &-top-none {
        border-top: none;
    }
}
