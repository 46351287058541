/* ==========================================================================
   # START TABLE COMPONENT
   ==========================================================================
*/
.a-table {
    display: table;
    width: 100%;
    @include border-radius(.4rem);
    border-collapse: collapse;
    text-align: left;

    thead {
        tr {
            th {
                position: relative;
                padding: 1.3rem 1.6rem 1.3rem 1.6rem;
                font: {
                    size: font-size(s-small);
                    weight: font-weight(regular);
                }
                white-space: nowrap;
                border-bottom: .1rem solid color(gray-E8);
                background-color: color(gray-F5);
                color: rgba(color(black), .85);

                .sort {
                    position: absolute;
                    top: 50%;
                    @include a-flex;
                    flex-direction: column;
                    right: 6px;
                    width: 14px;
                    height: 17px;
                    margin-top: -8.5px;
                    color: #BFBFBF;
                    -webkit-transition: all .3s;
                    transition: all .3s;

                    i {
                        &:not(:first-child) {
                            margin-top: .4rem;
                        }

                        font-size: font-size(s-small);
                        display: block;
                        height: .4rem;
                        line-height: .4rem;
                        -webkit-transition: all .3s;
                        transition: all .3s;

                        &.active {
                            color: color(primary);
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                &:first-child {
                    &.sticky-left {
                        position: sticky !important;;
                        background-color: color(gray-F5);
                        left: 0;
                        z-index: 10;
                    }
                }

                &:last-child {
                    &.sticky-right {
                        position: sticky !important;;
                        background-color: color(gray-F5);
                        right: 0;
                        z-index: 10;
                        &.box-shadow{
                            box-shadow: 0px 0 5px 0px rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 1.3rem 1.6rem 1.3rem 1.6rem;
                font: {
                    size: 1.4rem;
                }
                color: rgba(color(black), .85);
                border-bottom: .1rem solid color(gray-E8);
                transition: all .3s, border 0s;

                &:first-child {
                    &.sticky-left {
                        position: sticky !important;;
                        background-color: color(white);
                        left: 0;
                        z-index: 10;

                        &:hover {
                            cursor: pointer;
                            background-color: color(blue-E6);
                        }
                    }
                }

                &:last-child {
                    &.sticky-right {
                        position: sticky !important;;
                        background-color: color(white);
                        right: 0;
                        z-index: 10;
                        &.box-shadow{
                            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
                        }
                        &:hover {
                            cursor: pointer;
                            background-color: color(blue-E6);
                        }
                    }
                }
            }

            &:hover {
                td {
                    background-color: color(blue-E6);

                    &.sticky-left {
                        background-color: color(blue-E6);
                    }

                    &.sticky-right {
                        background-color: color(blue-E6);
                    }
                }
            }
        }
    }

    &--responsive {
        overflow-x: auto;
        @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
    }

    &--barcode-scan {
        border-radius: .3rem;

        tbody {
            tr {
                td {
                    font: {
                        size: font-size(medium);
                        weight: font-weight(regular);
                    }
                    color: rgba(color(black), .85);
                    white-space: nowrap;
                }

                .action-remove {
                    display: table-cell;
                    text-align: right;
                    height: 1.9rem;
                    i {
                        color: rgba(color(black), .45);

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            &.error {
                display: contents;

                tr {
                    background-color: color(red-FF);

                    &.data {
                        td {
                            position: relative;
                            border-bottom: none;

                            &:before {
                                position: absolute;
                                top: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: rgba(color(red), .5);
                            }
                        }
                    }

                    &.message {
                        td {
                            padding-top: 0 !important;
                            position: relative;

                            &:after {
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: rgba(color(red), .5);
                            }
                            i{
                                display: inline-flex;
                                align-items: baseline;
                                margin-top: 4px;
                            }
                        }
                    }
                }

                &:hover {
                    td {
                        background-color: rgba(color(red), .02);
                    }
                }
            }

            &.warning {
                display: contents;

                tr {
                    background-color: color(orange-FFF);

                    &.data {
                        td {
                            position: relative;
                            border-bottom: none;

                            &:before {
                                position: absolute;
                                top: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: color(orange-FFE);
                            }
                        }
                    }

                    &.message {
                        td {
                            position: relative;
                            padding-top: 0 !important;

                            &:after {
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: color(orange-FFE);
                            }
                            i{
                                display: inline-flex;
                                align-items: baseline;
                                margin-top: 4px;
                            }
                        }
                    }
                }

                &:hover {
                    td {
                        background-color: rgba(color(red), .02);
                    }
                }
            }
        }
    }

    &--history-scan-code {
        border-radius: .3rem;
        @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);

        table {
            thead {
                tr {
                    th {
                        padding-top: 1.4rem;
                        padding-bottom: 1rem;
                        font: {
                            size: font-size(s-small);
                        }
                        color: color(light-black);
                        background-color: color(gray-F5);
                        white-space: nowrap;

                        &:first-child {
                            position: -webkit-sticky;
                            position: sticky;
                            top: 0;
                            left: 0;
                            background-color: color(gray-F5);
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font: {
                            size: font-size(medium);
                        }
                        color: color(light-black);
                        white-space: nowrap;

                        &:first-child {
                            position: -webkit-sticky;
                            position: sticky;
                            top: 0;
                            left: 0;
                            background-color: color(white);
                        }
                    }
                }
            }
        }
    }

    &--error-fixed {
        .ant-table-fixed-right {
            right: 1px;
        }

    }

    &--antd {
        overflow-x: auto;
        @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);

        table {
            thead {
                tr {
                    th {
                        white-space: nowrap;
                        color: rgba(color(black), .85);
                        font: {
                            size: font-size(small);
                            weight: font-weight(regular);
                        }
                        .sticky-right {
                            position: sticky;
                            background-color: transparent;
                            right: 0;
                        }
                        .sticky-left {
                            position: sticky;
                            background-color: transparent;
                            left: 0;
                        }
                        &.a-notification-dot{
                            &:before{
                                width:0;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        color: rgba(color(black), .85);

                        .a-action-hover-show {
                            opacity: 0;
                        }
                        &.a-notification-dot{
                            &:before{
                                left:1.6rem;
                            }
                        }
                        &.a-notification-dot--table {
                            padding-left: 3.2rem !important;

                        }
                    }

                    &.row-show-item-hover {
                        .action-row-show-hover {
                            opacity: 0;
                        }

                        &:hover {
                            .action-row-show-hover {
                                opacity: 1;
                                -webkit-animation: opacity-shadow .3s linear;
                                -o-animation: opacity-shadow .3s linear;
                                animation: opacity-shadow .3s linear;
                            }
                        }
                    }

                    &:hover {
                        td {
                            .a-action-hover-show {
                                opacity: 1;
                                -webkit-animation: opacity-shadow .3s linear;
                                -o-animation: opacity-shadow .3s linear;
                                animation: opacity-shadow .3s linear;
                            }
                        }
                    }
                    &.ant-table-expanded-row {
                        background: #F5F5F5;
                        &:hover {
                            background: #F5F5F5;
                        }
                        th{
                            background: #F5F5F5;
                        }
                    }

                    &.active {
                        td {
                            background-color: color(blue-E6);
                        }
                    }
                }
            }
        }
    }

    &--follow {
        table {
            tbody {
                tr {
                    &.ant-table-expanded-row {
                        background-color: white;

                        td {
                            position: relative;
                            padding: 0;
                            &:before {
                                position: absolute;
                                top: -1px;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                content: '';
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }
    }

    &--delivery-request {
        table {
            tbody {
                tr {
                    &.ant-table-expanded-row {
                        background: #F0F2F5;
                        &:hover {
                            background: #F0F2F5;
                        }
                        th{
                            background: #F0F2F5;
                        }
                    }
                }
            }
        }
    }

    &--isdata--warning-error {
        border-radius: .3rem;

        tbody {
            tr {
                td {
                    font: {
                        size: font-size(medium);
                        weight: font-weight(regular);
                    }
                    color: rgba(color(black), .85);
                    white-space: nowrap;

                }

                .action-remove {
                    display: table-cell;
                    text-align: right;
                    height: 1.9rem;
                    i {
                        color: rgba(color(black), .45);

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

            }

            &.error {
                display: contents;

                &:hover {
                    tr {
                        td {
                            background-color: color(red-FF);
                        }

                        &.data {
                            td {
                                &.sticky-left {
                                    background-color: color(red-FF);
                                }

                                &.sticky-right {
                                    background-color: color(red-FF);
                                }
                            }
                        }
                    }
                }

                tr {
                    background-color: color(red-FF);

                    &.data {
                        td {
                            position: relative;
                            border-bottom: none;

                            &:before {
                                position: absolute;
                                top: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: rgba(color(red), .5);
                            }

                            &.sticky-left {
                                background-color: color(red-FF);
                                -webkit-box-shadow: none !important;
                                -moz-box-shadow: none !important;
                                box-shadow: none !important;
                                &:hover {
                                    background-color: color(red-FF);
                                }
                            }

                            &.sticky-right {
                                background-color: color(red-FF);
                                -webkit-box-shadow: none !important;
                                -moz-box-shadow: none !important;
                                box-shadow: none !important;
                                &:hover {
                                    background-color: color(red-FF);
                                }
                                svg {
                                    height: 1.9rem;
                                }
                            }
                        }
                    }

                    &.message {

                        td {
                            padding-top: 0 !important;
                            position: relative;

                            &:after {
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: rgba(color(red), .5);
                            }
                            i{
                                display: inline-flex;
                                align-items: baseline;
                                margin-top: 4px;
                            }
                        }
                    }
                }

            }

            &.warning {
                display: contents;

                &:hover {
                    tr {
                        td {
                            background-color: rgba(color(red), .02);
                        }

                        &.data {
                            td {
                                &.sticky-left {
                                    background-color: rgba(color(red), .02);
                                }

                                &.sticky-right {
                                    background-color: rgba(color(red), .02);
                                }
                            }
                        }
                    }
                }

                tr {
                    background-color: color(orange-FFF);

                    &.data {
                        td {
                            position: relative;
                            border-bottom: none;

                            &:before {
                                position: absolute;
                                top: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: color(orange-FFE);
                            }

                            &.sticky-left {
                                background-color: color(orange-FFF);
                                -webkit-box-shadow: none !important;
                                -moz-box-shadow: none !important;
                                box-shadow: none !important;
                                &:hover {
                                    background-color: rgba(color(red), .02);
                                }
                            }

                            &.sticky-right {
                                background-color: color(orange-FFF);
                                -webkit-box-shadow: none !important;
                                -moz-box-shadow: none !important;
                                box-shadow: none !important;
                                &:hover {
                                    background-color: rgba(color(red), .02);
                                }
                            }
                        }
                    }

                    &.message {
                        td {
                            position: relative;
                            padding-top: 0 !important;

                            &:after {
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background-color: color(orange-FFE);
                            }
                            i{
                                display: inline-flex;
                                align-items: baseline;
                                margin-top: 4px;
                            }
                        }
                    }

                    &:hover {
                        td {
                            &.sticky-left {
                                background-color: rgba(color(red), .02);
                            }

                            &.sticky-right {
                                background-color: rgba(color(red), .02);

                            }
                        }
                    }
                }

            }

            &.have-attention {
                &.error {
                    tr {
                        &.attentions {
                            td {
                                border-bottom: none;
                            }
                        }
                    }
                }
                tr {
                    &.data {
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    &--modal-list-package {
        max-height: 400px;
        overflow-y: auto;
        @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
    }

    &--antd-scroll-x {
        .ant-table-scroll {
            .ant-table-body {
                overflow-x: auto !important;
                @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
            }
        }
    }

    &--custom-table {
       tbody {
           tr {
               &.has-warning {
                    background-color: color(orange-FFF);
               }
           }
       } 
    }
}

/*Dữ liệu trả về khi table rỗng*/
.ant-table-placeholder {
    padding-top: 7rem;
    padding-bottom: 7rem;
    border-bottom: none;
}

.ant-table-thead > tr > th {
    white-space: nowrap;
    background-color: color(gray-F5);
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding: 13px 16px;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -13px -16px -14px;
}
.editable-row {
    .editable-cell-value-wrap {
        border: 1px solid transparent;
        cursor: pointer;
        padding: 4px 24px 4px 11px;
    }

    &:hover {
        .editable-cell-value-wrap {
            border-color: #d9d9d9;
        }
    }
}
/* ==========================================================================
   # END TABLE COMPONENT
   ==========================================================================
*/
