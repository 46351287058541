/* ==========================================================================
   # START ULTILITIES HIDDEN
   ==========================================================================
*/
.a-hidden {
    display: none !important;

    &--lg {
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
    }

    &--xl {
        @include media-breakpoint-up(lg) {
            display: none !important;
        }
    }
}
.clear-both {
    clear: both;
    &--lg{
        @include media-breakpoint-down(lg){
            clear: both;
        };
    }
    &--xl{
        @include media-breakpoint-up(xl){
            clear: both;
        };
    }
}
.visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}
/* ==========================================================================
   # END ULTILITIES HIDDEN
   ==========================================================================
*/