/* ==========================================================================
   # START MODAL COMPONENT
   ==========================================================================
*/
.a-modal {
    &--default {
        .ant-modal-header {
            background-color: color(gray-F3);

            .title-modal {
                font-size: font-size(large);
                font-weight: font-weight(medium);
                color: #262626;
            }
        }

        .ant-modal-body {
            color: rgba(color(black), .85);
            padding: 1.6rem 2.4rem 2.4rem 2.4rem;
        }

        .a-modal--body-note-package {

        }

        &__action{
            margin-top: 2.4rem;
        }
    }

    /*Chọn mẫu bao trong tạo bao*/
    &--choose-template-bag {
        .ant-modal-content {
            @include border-radius(.8rem);

            .ant-modal-body {
                padding: 0;

                .choose-template-bag--title {
                    @include a-flex;
                    justify-content: space-between;
                    padding: 1.2rem 1.6rem;
                    margin-bottom: 2rem;
                    background-color: color(gray-F3);
                    border-top-right-radius: .8rem;
                    border-top-left-radius: .8rem;

                    .title {
                        @include a-flex;
                        align-items: center;
                        font: {
                            size: font-size(large);
                            weight: font-weight(regular);
                        }
                        text-transform: uppercase;
                        color: rgba(color(black), .85);
                    }
                }

                .body {
                    max-height: 40rem;
                    overflow: auto;
                    @include customscroll(.6rem, color(gray-9F), 1rem);
                    padding-right: 1.4rem;
                    padding-left: 1.6rem;
                }
            }
        }
    }

    /*Modal tạo template mới trong*/
    &--create-template-bag {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0;

                .create-template-bag--title {
                    @include a-flex;
                    justify-content: space-between;
                    padding: 1.2rem 1.6rem;
                    background-color: color(primary);
                    border-top-left-radius: .4rem;
                    border-top-right-radius: .4rem;

                    .title {
                        @include a-flex;
                        align-items: center;
                        font: {
                            size: font-size(large);
                            weight: font-weight(regular);
                        }
                        text-transform: uppercase;
                        color: color(white);
                    }
                }

                .body {
                    padding: 1.7rem 2.4rem 3rem 2.4rem;

                    .group-input {
                        label {
                            margin-bottom: .7rem;
                            font: {
                                size: font-size(medium);
                                weight: font-weight(regular);
                            }
                            color: rgba(color(black), .85);
                            line-height: 2.2rem;
                        }
                    }

                    .action {
                        margin-top: 2.4rem;
                    }
                }
            }
        }
    }

    /*Modal danh sach kien trong bao*/
    &--package-in-bag {
        .ant-modal-header {
            background-color: #F3F3F3;

            .title-modal {
                @include a-flex;
                align-items: center;
                color: rgba(color(black), .85);
                font: {
                    size: 1.6rem;
                    weight: font-weight(bold);
                }

                img {
                    margin-right: .8rem;
                }

            }
        }

        .ant-modal-content {
            .ant-modal-close-x {
              color: color(black);
            }
        }

        &.request-deliverynote {
            .ant-modal-body{
                padding: 1.4rem 0 0 0;
                .ant-table-content {
                    height: 35rem;
                }
            }
        }
        .action-modal {
            padding: 1.2rem 2.4rem;
            margin-top: .5rem;
            @include box-shadow(0, 0, .6rem, rgba(color(black), .16));
        }
    }

    /*Modal thêm nhân viên vào kho trong quản lý cấu hình kho*/
    &--add-employee {
        width: 100rem !important;
        height: 48rem;

        .ant-modal-content {
            height: 100%;

            .ant-modal-body {
                height: 100%;
                padding: 0;

                .title{
                    border-top-left-radius: .4rem;
                }

                .title{
                    border-top-right-radius: .4rem;
                }
            }
        }
    }

    &--add-attention {
        padding-bottom: 0;

        &__body {
            height: 100%;
            padding: 1.6rem 2.4rem 0 2.4rem;
            overflow: scroll;
            @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
        }

        &__action {
            padding: 2.4rem;
            margin-top: 0rem;
            border-top: 1px solid color(gray-D9);
        }

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-content {
            @include a-flex;
            flex-direction: column;
            height: 100%;

            .ant-modal-body {
                @include a-flex;
                flex-direction: column;
                height: 100%;
                flex: 1;
                overflow: auto;
            }
        }
    }

    &--warning {
        .ant-modal-confirm-content {
            margin-top: 0
        }
    }
}

.ant-modal-mask {
    z-index: 1000;
}

.ant-modal-wrap {
    z-index: 1000;
}

.a-drawer {
    &--close {
        position: absolute;
        top: 10rem;
        left: -3.5rem;
        @include a-flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 3.5rem;
        background-color: color(white);
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;

        &:hover {
            cursor: pointer;
        }

        i {
            font-size: font-size(large);
            color: rgba(color(black), .45);
        }
    }

    &--inventory {
        .title {
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            padding-bottom: 1.6rem;

        }

        .ant-drawer-body {
            padding-left: 0;
            padding-right: 0;

            .info {
                padding: 2.4rem;
                border-top: .1rem solid #D8D8D8;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
            }
        }
    }
}

.ant-drawer-wrapper-body {
    @include customscrolltrack(.6rem, color(gray-D9), 1.4rem);
}

.ant-modal-confirm {
    .ant-modal-body {
        .ant-btn-primary {
            background-color: color(primary) !important;
            border-color: color(primary) !important;
        }
    }

}

.a-follow-attention-modal {
    &__item {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &__title {
            @include a-flex;
            justify-content: space-between;
            padding-bottom: 4px;
            border-bottom: 1px solid color(gray-D9);
        }

        &__log {
            margin-top: 1.2rem;

            .log-item {
                margin-bottom: .4rem;
                font-size: 14px;
                color: rgba(color(black), .85);
                font-weight: bold;

                .log-item__time {
                    font-weight: 400;
                    margin-right: 1.2rem;
                }
            }
        }

        &__attention {
            margin-top: 1.2rem;

            .attention-item {
                margin-bottom: 1.2rem;

                &__title {
                    @include a-flex;
                    justify-content: space-between;

                    &__desc {
                        font-size: 12px;
                        color: #B4B6B8;
                        font-weight: 500;
                        line-height: 2.1rem;
                    }
                }

                .attention-item--time {
                    font-weight: 400;
                }
            }
        }
    }

    &__title {
        text-transform: uppercase;
        color: #262626;
        line-height: 22px;
        font-weight: 500;
    }
}

.modal-logs-by-attention {
    .ant-modal-header {
        background: #efefef;
        text-transform: uppercase;
    }
    .attention-log-row {
        border-bottom: 1px solid #f1f1f1;
        padding: 10px 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: 1px solid transparent;
        }
    }
}

/* ==========================================================================
   # END MODAL COMPONENT
   ==========================================================================
*/
