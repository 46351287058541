.delivery-note__info-money {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid color(border);
    @include media-breakpoint-down(lg) {
        margin-left: 12px;
        margin-right: 12px;
    }

    .money-title {
        font: {
            size: 1.4rem;
            weight: 700;
        }
        line-height: 2.2rem;
        color: rgba(color(black), 0.65);
    }

    .more-info {
        @include a-flex;
        align-items: center;

        i {
            @include a-flex;
            align-items: center;
            justify-content: center;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.8rem;
            font-size: 1.3rem;
            @include border-radius(0.4rem);
            border: 0.1rem solid color(primary);
        }
    }
}

.list-package-modal {
    margin-bottom: -1.6rem;

    &__title {
        margin-top: -0.2rem;
        padding-bottom: 0.8rem;
        border-bottom: 0.1rem solid color(border);

        h2 {
            font: {
                size: font-size(small);
                weight: font-weight(bold);
            }
            line-height: 1.6rem;
            color: #595959;
        }
    }

    &__item {
        @include a-flex;
        flex-direction: row;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;

        &:not(:last-child) {
            border-bottom: 0.1rem solid color(border);
        }
    }

    &--link {
        @include a-flex;
        width: calc(100% / 3);
    }
}

.list-export-in-customer {
    .a-content__top--page-list {
        padding: 1.6rem;
    }

    .a-content__body--page-list {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-bottom: 1.6rem;
    }
}

.create-delivery-note {
    .delivery-note-list {
        .ant-collapse-content-box {
            padding: 0;
        }
    }

    .a-sub-main.form-customer {
        margin-bottom: 6.7rem;

        .a-content--action-fixed-bottom {
            right: 1.7rem;
            width: calc(100% - 300px - 55px - 5.7rem);
        }
    }
}

.update-delivery-note {
    .a-sub-main {
        margin-bottom: 6.7rem;

        .a-content--action-fixed-bottom {
            right: 1.7rem;
            width: calc(100% - 300px - 55px - 5.7rem);
        }
    }
}

.create-delivery-note,
.update-delivery-note {
    .a-sub-sider {
        .sticky-container {
            &:not(.is-fixed) {
                .scrollbar-container {
                    min-height: calc(100vh - 8.8rem) !important;
                    height: calc(100vh - 8.8rem) !important;
                }
            }

            &.is-fixed {
                .sticky {
                    .scrollbar-container {
                        min-height: 100vh !important;
                        height: 100vh !important;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .create-delivery-note {
        .a-sub-main.form-customer {
            .a-content--action-fixed-bottom {
                width: calc(100% - 37.5rem);
            }
        }
    }

    .update-delivery-note {
        .a-sub-main {
            .a-content--action-fixed-bottom {
                width: calc(100% - 37.5rem);
            }
        }
    }

    .create-delivery-note,
    .update-delivery-note {
        .a-sub-sider {
            width: 270px !important;
            min-width: 270px !important;
            max-width: 270px !important;
        }

        .a-content--action-fixed-bottom {
            width: calc(100% - 200px - 55px - 11.7rem);
        }
    }
}

.delivery-note {
    &__domestic-shipping-orders {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 9.6rem);

        .orders {
            border-right: 1px solid color(border);
            display: flex;
            flex-direction: column;
            height: 100%;

            & > div:last-child {
                flex-grow: 1;
                min-height: 0;
            }
        }

        .order-list {
            list-style-type: none;
            margin-bottom: 0;

            .order-item {
                cursor: pointer;
                padding: 1.2rem 1.6rem;

                &.active {
                    background-color: color(blue-F0);
                    border-left: 2px solid color(primary);
                    color: color(primary);
                }
            }
        }

        .order-detail {
            display: flex;
            flex-direction: column;

            .bags,
            .packages {
                flex-grow: 1;
            }
        }

        .a-tile {
            border-top: 1px solid color(border);
        }

        textarea.ant-input {
            vertical-align: middle;
        }

        .actions {
            align-items: center;
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
            padding: 1.6rem;
        }

        & > section {
            &:last-child {
                flex-grow: 1;
                min-height: 0;
            }
        }
    }
}

.customer-input {
    &:disabled {
        color: rgba(color(black), 0.85)
    }
}
.customer-select .ant-select-disabled .ant-select-selection__choice__content {
    color: rgba(color(black), 0.85) !important
}

.customer-select-without-truncate {
    .ant-select-selection__choice__content {
        white-space: normal;
        height: fit-content;
        overflow: initial;
        text-overflow: initial;
    }
    .ant-select-selection__choice{
        height: fit-content !important;
        //margin-right: 0;
    }
    ul{
        display: inline-flex;
    }
}

.can-deliveries table tbody tr td {
    font-size: 12px;
}
