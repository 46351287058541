/* ==========================================================================
   # START FUNCTION SETTING
   ==========================================================================
*/

/*Start build Z-index*/
@function z-index($z-index) {
    @return var(--z-index-#{$z-index});
}

/*End build Z-index*/

/*Start build Color*/
@function color($color-name) {
    @return var(--color-#{$color-name});
}
/*End build Color*/

/*Start build Width*/
@function width($height, $width-variant:null) {
    // z-index variant is optional
    @if ($width-variant != null) {
        // map inception, need two names
        @return var(--width-#{$height}-#{$width-variant});
    } @else {
        // single-level z-index, one name
        @return var(--width-#{$width-variant});

    }
}
/*End build Width*/

/*Start build Height*/
@function height($height, $height-variant:null) {
    // z-index variant is optional
    @if ($height-variant != null) {
        // map inception, need two names
        @return var(--height-#{$height}-#{$height-variant});
    } @else {
        // single-level z-index, one name
        @return var(--height-#{$height-variant});

    }
}
/*End build Height*/

/*Start build Font-size*/
@function font-size($font-size) {
    @return var(--font-size-#{$font-size});
}
/*End build Font-size*/

/*Start build Font-weight*/
@function font-weight($font-weight) {
    @return var(--font-weight-#{$font-weight});
}
/*End build Font-weight*/

/*Start map_get variable css*/
@function get-map($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}
/*End map_get variable css*/


/*KEY FRAME*/

/*Key frame xoay vòng tròn*/
@include keyframes(circle) {
    100% { transform: rotate(-360deg); }
}
/*End*/
/*Key frame box-shadow khi button được visited*/
@include keyframes(waveEffect) {
    100% {
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        border-width: 6px; }
}
@include keyframes(fadeEffect) {
    100% {
        opacity: 0; }
}
/*End*/

/*Key framre đổi màu chữ*/
@include keyframes(hue) {
    from {
        -webkit-filter: hue-rotate(0deg);
    }
    to {
        -webkit-filter: hue-rotate(-360deg);
    }

}
/*Key frame di chuyển từ trên xuống dưới*/
@include keyframes(top-bottom--warning-bill-code){
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

/*Keyframe opacity */
@include keyframes(opacity-shadow){
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

/*Keyframe opacity */
@include keyframes(opacity-shadow7){
    from {
        opacity: .7;
    }
    to{
        opacity: 1;
    }
}
/*Key framre height*/
@include keyframes(height100) {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }

}
/*Keyframe show error*/
@include keyframes(show-error-text){
    from{
        margin-top:-1rem;
    }
    to{
        margin-top:.3rem;
    }
}

/*Keyframe show input inline*/
@include keyframes(show-input-inline){
    from{
        margin-top:-1rem;
        opacity: 0;
    }
    to{
        margin-top:0;
        opacity: 1;
    }
}
@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }
    50% {
        width: 30%;
    }
    70% {
        width: 70%;
    }
    80% {
        left: 50%;
    }
    95% {
        left: 120%;
    }
    to {
        left: 100%;
    }
}

@keyframes dots {
    0%, 20% {
        color: black;
        text-shadow: .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: black;
        text-shadow: .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: .25em 0 0 black,
        .5em 0 0 rgba(0, 0, 0, 0);
    }
    80%, 100% {
        text-shadow: .25em 0 0 black,
        .5em 0 0 black;
    }
}

/* ==========================================================================
   # END FUNCTION SETTING
   ==========================================================================
*/