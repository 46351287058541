/* ==========================================================================
   # START TAB COMPONENT
   ==========================================================================
*/
.a-tabs {
  &--chatbox-tab {
    font: {
      size: 1.2rem;
      weight: font-weight(regular);
    };

    .ant-tabs-bar {
      padding-bottom: .1rem;
      border-bottom: .1rem solid color(border);

      .ant-tabs-nav-wrap {
        background-color: color(gray-FA);
      }
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        padding: .7rem 1rem;
        margin-right: 1rem;
        font: {
          size: 1.2rem;
          weight: font-weight(regular);
        };
      }

      .ant-tabs-tab-active {
        color: color(text-primary);
      }
    }

    .ant-tabs-ink-bar {
      background-color: color(text-primary);
    }

    &--panel {
      padding: 0 2.1rem 1.8rem 1.6rem;
      overflow-y: auto;
      max-height: calc(100vh - #{ get-map($heights, "header", "lg")} - 16rem);

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: color(gray-9F);
          border-radius: 10px;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;

        &:horizontal {
          height: 5px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: color(gray-9F);
        cursor: pointer;
      }
    }

    &.bag-detail {
      .ant-tabs-bar {
        margin-bottom: 0;
      }
    }
  }

  &--chatbox {
    font: {
      size: 1.2rem;
      weight: font-weight(regular);
    };

    .ant-tabs-bar {
      padding-bottom: .1rem;
      border-bottom: .1rem solid color(border);

      .ant-tabs-nav-wrap {
        background-color: color(gray-FA);
      }
    }

    .ant-tabs-nav {

      .ant-tabs-tab {
        padding: 1.1rem 1.5rem;
        font: {
          size: 1.2rem;
          weight: font-weight(regular);
        };
      }

      .ant-tabs-tab-active {
        color: color(text-primary);
      }
    }

    .ant-tabs-ink-bar {
      background-color: color(text-primary);
    }

    &--panel {
      padding: 0 2.1rem 1.8rem 1.6rem;
      overflow-y: auto;
      max-height: calc(100vh - #{ get-map($heights, "header", "lg")} - 8rem);

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: color(gray-9F);
          border-radius: .7rem
        }
      }

      &::-webkit-scrollbar {
        width: .6rem;

        &:horizontal {
          height: .6rem;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 1rem;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: color(gray-9F);
        cursor: pointer;
      }
    }
  }

  /*Tab danh danh sách trong lịch sử kiểm kê*/
  &--inventory-history {
    .ant-tabs-bar {
      margin-bottom: 0;
      margin-right: 2.4rem;
      background-color: color(white);
      @include media-breakpoint-down(lg) {
        margin-right: 1.6rem;
      }
    }

    .ant-tabs-nav .ant-tabs-tab {
      padding: 1.5rem 0;
      text-transform: uppercase;
    }

    .ant-tabs-nav {
      margin-left: 2.4rem;
    }

    .ant-tabs-ink-bar {
      bottom: 1px;
    }
  }

  /* Tab trong danh sách phiếu xuât */
  &--container {
    .ant-tabs-bar {
      margin-right: 2.4rem;
      margin-bottom: 0;
      background-color: color(white);
      border-top-left-radius: .4rem;
      border-top-right-radius: .4rem;
      @include media-breakpoint-down(lg) {
        margin-right: 1.6rem;
      }

      .ant-tabs-nav {
        .ant-tabs-tab {
          color: rgba(color(black), .45);
          font: {
            size: font-size(large);
            weight: font-weight(regular);
          };
          -webkit-transition: ease all .4s;
          -moz-transition: ease all .4s;
          -ms-transition: ease all .4s;
          -o-transition: ease all .4s;
          transition: ease all .4s;
        }

        .ant-tabs-tab-active {
          color: rgba(color(black), .85);
          font: {
            size: font-size(large);
            weight: font-weight(bold);
          };
          -webkit-transition: ease all .4s;
          -moz-transition: ease all .4s;
          -ms-transition: ease all .4s;
          -o-transition: ease all .4s;
          transition: ease all .4s;
        }

        .ant-tabs-ink-bar {
          background-color: color(primary);
        }
      }
    }
  }

  /*Tab hiển thị thông báo trên header*/
  &__notification {
    .ant-tabs-bar {
      margin-bottom: 0;

      .ant-tabs-nav {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .ant-tabs-nav-container-scrolling {
        .ant-tabs-nav {
          padding-left: 0;
        }
      }

      .ant-tabs-tab {
        margin-right: 0;
        font-size: font-size(s-small);

        &:hover {
          color: color(primary);
        }
      }

      .ant-tabs-tab-active {
        color: color(primary) !important;
      }

      .ant-tabs-ink-bar {
        background-color: color(primary);
      }
    }
  }

  &--delivery-request {
    .ant-tabs-bar {
      margin-bottom: 0;
      margin-right: 2.4rem;
      background-color: color(white);
      @include media-breakpoint-down(lg) {
        margin-right: 1.6rem;
      }
    }

    .ant-tabs-nav .ant-tabs-tab {
      padding: 1.5rem 1.6rem;
      margin-right: 2.4rem;
      font: {
        size: font-size(large);
        weight: font-weight(medium);
      }
      color: color(gray);

      &.ant-tabs-tab-active {
        color: rgba(color(black), .85);
        font-weight: font-weight(bold);
      }
    }

    .ant-tabs-ink-bar {
      bottom: 1px;
      background-color: color(primary);
    }
  }
}

/*` ==========================================================================
   # END TAB COMPONENT
   ==========================================================================
*/
