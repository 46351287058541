/* ==========================================================================
   # START CHECKBOX COMPONENT
   ==========================================================================
*/
.a-checkbox {
    /*Checkbox trong thiết lập cột thông tin ở table*/
    &--set-field {
        @include a-flex;
        .ant-checkbox {
            margin-top: .3rem;
        }
        span {
            &:not(:first-child) {
                word-break: break-word;
                white-space: initial;
            }
        }
    }
    /*End*/
}
/* ==========================================================================
   # END CHECKBOX COMPONENT
   ==========================================================================
*/