/* ==========================================================================
   # START CHAT COMPONENT
   ==========================================================================
*/

.a-chatbox {
  width: width(chatbox, lg);
  height: calc(100vh - #{ get-map($heights, "header", "lg")} - 2.4rem);
  background-color: color(white);
  border: .1rem solid color(border);
  @include css-transition('ease all .4s');
  margin-left: 2.4rem;
  @include media-breakpoint-down(lg) {
    width: width(chatbox, sm);
    margin-left: 1.6rem;
  }

  @include media-breakpoint-down(lg) {
    width: width(chatbox, sm);
    margin-left: 1.6rem;
    right: 1.6rem;
  }

  .a-chatbox--body {
    height: 100%;
    @include a-flex;

    .a-tabs--chatbox-tab {
      width: 100%;
      height: 100%;
      @include a-flex;
      flex-direction: column;

      .ant-tabs-bar {
        margin-bottom: 0;
      }
    }

    .ant-tabs-top-content {
      height: 100%;
    }

    .list-chat-spinner {
      height: 100%;
      &__customer-chat {
        height: 45%;
      }
      &__internal-chat {
        top: 46px;
        height: 40%;
        border-top: 1px solid #ececec;
      }

      .ant-spin-container {
        height: 100%;
      }
    }

    .list-chat-checking-package {

    }
  }

  .a-tabs--chatbox--panel {
    max-height: 100% !important;
    overflow-y: auto !important;
    padding-bottom: 0rem;
    padding-right: 1.6rem;
    padding-top: 1.6rem;
  }

  .a-group--upload {
    display: flex;
    position: absolute;
    right: 16px;
    top: 2px;

    .ant-upload-list {
      background-color: white;
      position: absolute;
      right: 0;
      width: 260px;
      top: 24px;
    }
  }

  .a-tabs--chatbox--panel-chat {
    display: flex;
    flex-direction: column;

    .list-chat-checking-package {
      padding: 1.6rem 1.6rem 0 1.6rem;
      height: 95%;
      overflow-y: auto;
      @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);
    }

    .list-chat-checking-bag {
      padding: 1.6rem 1.6rem 0 1.6rem;
      height: 95%;
      overflow-y: auto;
      @include customscrolltrack(.6rem, color(gray-9F), 1.4rem);

      .empty-row {
        height: 4rem;
      }
    }


    .ant-upload-list {
      background-color: white;
    }
  }

  &.checkpackage {
    width: width(chatbox, lg);
    background-color: color(white);
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem;
    height: 100%;
    position: absolute;
    right: 2.4rem;

    @include a-flex;
    flex-direction: column;

    &.scroll {
      .a-tabs--chatbox--panel {
        max-height: calc(100% - 3.3rem) !important;
      }
    }
  }

  &.scroll {
    @include css-transition('ease all .4s');
    height: 100vh;
    position: fixed;
    //right: 2rem;
    top: 0;
    margin-top: 0;
    @include media-breakpoint-down(lg) {
      right: 1.6rem;
    }

    .a-tabs--chatbox--panel {
      max-height: calc(100vh - 7.5rem);
    }
  }

  &::-webkit-scrollbar {
    width: 6px;

    &:horizontal {
      height: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: color(gray-9F);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: color(gray-9F);
    cursor: pointer;
  }

  &--input {
    @include a-flex;
    padding: 1.6rem 1.6rem 1.2rem 1.6rem;
    background-color: color(gray-FA);
    border-bottom: .1rem solid color(border);
    position: relative;
  }

  &--chat-input {
    position: relative;
    border-bottom: 1px solid color(border);

    .a-input--chatbox {
      border: none;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        box-shadow: none;
      }
    }

  }

  .chat-heading {
    font-weight: bold;
    padding: 7px 0 5px 10px;
    border-bottom: 1px dashed #eaeaea;
  }

}

.a-chatbox--log {
  @include a-flex;

  &.system {
    margin-top: 1.2rem;
    margin-bottom: 2.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.user {
    padding-bottom: 1.2rem;
  }

  &:nth-child(2) {
    margin-top: 0;
  }

  .img-ads {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: .9rem;
    object-fit: cover;
    @include border-radius(50%);
  }

  &--detail {
    flex: 1;

    .title {
      margin-bottom: .4rem;
      font: {
        size: 0.95rem;
        weight: font-weight(regular);
      };
      line-height: 1rem;
      color: #CCCCCC;
    }

    .detail {
      font: {
        size: font-size(s-small);
        weight: font-weight(regular);
      };
      color: rgba(color(black), .85);
    }

    .attachment-item {
      display: block;
      font-size: 10px;

      .img {
        max-width: 100%;
        margin-bottom: 10px;
      }
    }

  }

}

.alog {
  width: width(chatbox, lg);
  background-color: color(white);
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
  height: calc(100vh - 17rem);
  position: fixed;
  right: 2.4rem;
  @include media-breakpoint-down(lg) {
    width: width(chatbox, sm);
    margin-left: 1.6rem;
    right: 1.6rem;
  }

  &.not-navigation-fixed {
    @include css-transition('ease all .4s');
    height: calc(100vh - 10.5rem);
  }

  &.scroll {
    @include css-transition('ease all .4s');
    height: calc(100vh - 6.6rem);
    position: fixed;
    right: 2.4rem;
    top: 0;
    margin-top: 0;
  }

  > .title {
    padding: 1.5rem 1.2rem;
    border-bottom: .1rem solid color(border);
    font: {
      size: 1.6rem;
      weight: bold;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .list-log {
      height: calc(100% - 54px);
      overflow-y: scroll;
      scroll-behavior: smooth;

      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: color(gray-9F);
        }
      }

      &::-webkit-scrollbar {
        width: .6rem;

        &:horizontal {
          height: .6rem;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: inherit;
        border-radius: 10px;
      }
    }
  }

  .log-block {
    @include a-flex;
    padding: 1rem 1.2rem 0 1.2rem;

    .img-ads {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: .9rem;
      object-fit: cover;
      @include border-radius(50%);
    }

    .log-detail {
      flex: 1;

      .title {
        margin-bottom: 0;
        font: {
          size: 1rem;
          weight: font-weight(regular);
        };
        color: #CCCCCC;
      }

      .detail {
        font: {
          size: font-size(s-small);
          weight: font-weight(regular);
        };
        color: rgba(color(black), .85);
        line-height: 2rem;
      }

    }

  }
}

/*` ==========================================================================
   # END CHAT COMPONENT
   ==========================================================================
*/
