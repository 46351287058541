.a-content--bag {
  &--title {
    @include media-breakpoint-down(lg) {
      flex-direction: row !important;
      align-items: center !important;
    }

    .right {
      @include media-breakpoint-down(lg) {
        @include a-flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 0 !important;
      }
    }
  }
}

/*Danh sách kiện trong bao ở trang chi tiết bao*/
.list-package-in-bag {
  margin-top: 2rem;
  margin-bottom: 4.8rem;

  .a-content__top--page-list {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
  }

  .content-list-package-in-bag {
    padding-right: 2.4rem;

    &--empty {
      padding: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: color(white);

      .info {
        margin-top: 1.5rem;
        font-size: font-size(x-large);
        color: rgba(color(black), .25);
        font-weight: font-weight(bold);
      }
    }
  }
}

/*Thông tin bên phải trang chi bao*/
.info-right-detail-bag {
  width: width(chatbox, lg);
  height: calc(100vh - #{ get-map($heights, "header", "lg")} - 2.4rem);
  background-color: transparent;
  @include css-transition('ease all .4s');
  margin-left: 2.4rem;
  color: rgba(color(black), .85);
  @include media-breakpoint-down(lg) {
    width: width(chatbox, sm);
    margin-left: 1.6rem;
  }

  &.scroll {
    @include css-transition('ease all .4s');
    height: 100vh;
    position: fixed;
    right: 2rem;
    top: 0;
    margin-top: 0;
    @include media-breakpoint-down(lg) {
      right: 1.6rem;
    }

    .log-system.bag-detail {
      right: 0;
    }
  }

  .block {
    height: 50%;
    background-color: color(white);

    &.log-system-collapse {
      @include css-transition('ease all .4s');
      height: calc(100% - 7.8rem);
    }

    &.bag-today {
      @include css-transition('ease all .4s');

      &::-webkit-scrollbar {
        width: .6rem;

        &:horizontal {
          height: .6rem;
        }
      }

      &::-webkit-scrollbar-track {
        background: color(white);
      }

      &::-webkit-scrollbar-thumb {
        background: color(gray-D9);
        border-radius: 1.4rem;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: color(gray-D9);
        cursor: pointer;
      }
    }

    .title-block {
      @include a-flex;
      padding: 1.5rem;
      color: rgba(color(black), .85);
      font-size: font-size(large);
      font-weight: font-weight(bold);
    }

    .a-form--group-button--warehouse {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    /*Danh sách bao cùng ngày*/
    .list-bag-today {
      height: 100%;

      &--list {
        height: calc(100% - 5.4rem);
        padding-bottom: 0;
        overflow-y: auto;
        position: relative;

        .ant-spin-nested-loading {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        &::-webkit-scrollbar {
          width: .6rem;

          &:horizontal {
            height: .6rem;
          }
        }

        &::-webkit-scrollbar-track {
          background: color(white);
        }

        &::-webkit-scrollbar-thumb {
          background: color(gray-D9);
          border-radius: 1.4rem;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: color(gray-D9);
          cursor: pointer;
        }
      }

      &--item {
        position: relative;
        list-style-type: none;
        padding-left: 1.3rem;
        padding-right: 1.4rem;
        transition: ease all .3s;

        &:hover {
          cursor: pointer;
          background-color: #F0F5FF;

          &:before {
            height: 100%;;
            animation: height100 .3s linear;
          }
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: .2rem;
          height: 0;
          background-color: color(primary);
        }

        &.active {
          background-color: #F0F5FF;

          &:before {
            height: 100%;;
          }
        }
      }

      &--link {
        @include a-flex;
        align-items: center;
        width: 100%;
        height: auto;
        padding-top: 1.1rem;
        padding-bottom: 1.1rem;
        border-bottom: .1rem solid color(border);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: inherit;
        font: {
          size: 1.6rem;
          weight: 400;
        }
      }
    }

    &.log-system {
      height: calc(50% - 2rem);
      margin-top: 2rem;
      border: .1rem solid color(border);

      &.collapse {
        height: 4.8rem;
        @include css-transition('ease all .4s');

        .list-log {
          display: none;
        }
      }

      .title-block {
        padding: 1.2rem 1.5rem 1.2rem 1.5rem;
        background-color: color(gray-FA);
        position: relative;

        .icon-collapse {
          position: absolute;
          top: .3rem;
          right: .3rem;
          color: color(primary);
          transition: ease all .3s;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .list-log {
        max-height: calc(100% - 4.8rem);
        border-top: .2rem solid color(border);
        padding: 1.2rem;
        overflow: auto;

        &::-webkit-scrollbar {
          width: .6rem;

          &:horizontal {
            height: .6rem;
          }
        }

        &::-webkit-scrollbar-track {
          background: color(white);
        }

        &::-webkit-scrollbar-thumb {
          background: color(gray-D9);
          border-radius: 1.4rem;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: color(gray-D9);
          cursor: pointer;
        }

        li {
          @include a-flex;
          margin-bottom: 1rem;
          list-style-type: none;

          .time {
            font-size: 1rem;
            font-weight: font-weight(regular);
            white-space: nowrap;
          }

          .detail {
            font-size: 1rem;
            font-weight: font-weight(regular);
            margin-left: .5rem;
          }
        }
      }
    }
  }
}

/*Lịch sử bao trong chi tiết kiện*/
.a-history-bag {
  position: relative;
  padding-top: .8rem;

  &::before {
    position: absolute;
    content: '';
    width: 2px;
    height: 100%;
    left: 0;
    bottom: 2.5rem;
    background-color: #C3C3C3;
  }

  &__item {
    position: relative;
    margin-bottom: 1.6rem;
    padding-left: 1.5rem;
    list-style-type: none;

    &::before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      left: -2px;
      bottom: 2.5rem;
      background-color: #C3C3C3;
      @include border-radius(50%);
    }

    &.active {
      &::before {
        width: 8px;
        height: 8px;
        left: -3px;
        background-color: color(primary);
      }
    }

    .time-status {
      margin-bottom: .2rem;
      font-size: 1.1rem;
      color: rgba(15, 15, 15, .85);
      line-height: 15px;
    }

    .name-bag {
      font-size: 14px;
      color: color(primary);
      line-height: 19px;

      .icon-bag {
        margin-right: .5rem;
      }
    }
  }
}

.warehouse-status-card {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  padding: .4rem 0;

  &--success {
    background-color: #52c41a;
    color: #fff;
  }
  &--warning {
    background-color: #faad14;
    color: #fff;
  }
  &--error {
    background-color: #f82804;
    color: #fff;
  }
}
