@page {
    margin: 5mm;
}

@media print {
    html,
    body {
        background-color: #ffffff !important;
        color: black !important;
        font-family: 'Roboto', sans-serif;
        height: auto !important;
        width: auto !important;
        -webkit-print-color-adjust: exact;
    }
    thead {
        display: table-header-group;
    }
    th {
        background-color: #fafafa !important;
        -webkit-print-color-adjust: exact;
    }
    .print-delivery-note,
    .print-delivery-note-for-customer {
        width: 100%;
    }
    .print-delivery-note--header,
    .print-delivery-note-for-customer--header {
        padding-right: 2px;
    }
    .info--label {
        color: rgba(color(black), 0.85) !important;
        font-weight: font-weight(regular) !important;
    }
    .info--group {
        margin-bottom: 8px;
    }
}

.print-delivery-note,
.print-delivery-note-for-customer {
    color: black;

    table {
        width: 100%;
    }

    &--header {
        z-index: 199;
        position: fixed;
        top: 0mm;
        width: 100%;
        border-bottom: 0.1rem solid rgba(color(black), 0.45);
        background-color: color(white);
        padding-bottom: 8px;
        padding-right: 30px;
        .print-header-title {
            font: {
                size: 10px;
                weight: font-weight(bold);
            }
            line-height: 16px;
            color: color(text-primary-black);
        }

        .print-header-description {
            font: {
                size: 10px;
                weight: font-weight(regular);
            }
            line-height: 16px;
            color: color(text-primary-black);
            margin-bottom: 0;
        }
    }

    &--list {
        width: 100%;

        .a-table--antd {
            table {
                width: 100%;

                thead {
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                    tr {
                        th {
                            font-size: 10px;
                            &:first-child {
                                border-top-left-radius: 0;
                            }
                            &:last-child {
                                border-top-right-radius: 0;
                            }
                            border-bottom: 1px solid #e6e6e6;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding-top: 4px;
                            padding-bottom: 4px;
                            font-size: 10px;
                            border-bottom: 1px solid #e6e6e6;
                        }
                    }
                }
            }
        }
    }

    &--info {
        width: 100%;
        background-color: color(white);
        padding-bottom: 0;

        &.a-block-info {
            .info--label {
                color: rgba(color(black), 0.85);
                font-weight: font-weight(regular);
                font-size: 10px !important;
                line-height: 14px;
            }

            .info--value {
                font-size: 10px !important;
                line-height: 14px;
            }

            .info--group {
                margin-bottom: 8px;
            }
        }
    }
}

.page-header-space {
    height: 72px;
}

.print-delivery-note {
    @media print {
        tfoot {
            display: table-footer-group;
        }
        .a-table--antd {
            table {
                thead {
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                    tr {
                        th {
                            border-bottom: 1px solid #e6e6e6;
                            &:first-child {
                                border-top-left-radius: 0;
                                padding-left: 0 !important;
                            }
                            &:last-child {
                                border-top-right-radius: 0;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 0 !important;
                            }
                            border-bottom: 1px solid #e6e6e6;
                        }
                    }
                }
            }
        }

        .print-delivery-note--header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.45) !important;
        }

        .delivery-note-info--title {
            p {
                @include a-flex;
            }
        }
    }

    &--list {
        .delivery-note-list--title {
            margin-bottom: 1.2rem;

            h2 {
                font: {
                    size: 13px;
                    weight: font-weight(bold);
                }
                line-height: 18px;
                color: color(text-primary-black);
                text-transform: uppercase;
            }
        }
    }

    &--info {
        .delivery-note-info--title {
            padding-top: 12px;
            margin-bottom: 12px;

            h1 {
                font: {
                    size: 13px;
                    weight: font-weight(bold);
                }
                line-height: 20px;
                color: color(text-primary-black);
                text-transform: uppercase;
            }

            p {
                font-size: 10px;
                font-weight: 400;
                line-height: 14px;
                color: rgba(color(black), 0.85);
                margin-bottom: 0;
            }
        }
    }

    &--footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: color(white);
        .delivery-note--person {
            @include a-flex;
            justify-content: space-between;
            margin-top: 1rem;
            padding-top: 0.8rem;
            border-top: 0.1rem solid rgba(color(black), 0.65);
            padding-left: 90px;
            padding-right: 90px;

            .person-action {
                text-align: center;

                p {
                    padding-bottom: 24px;
                    font: {
                        size: 10px;
                        weight: 400;
                    }
                    line-height: 1.9rem;
                    color: rgba(color(black), 0.85);
                    margin-bottom: 0;
                }

                span {
                    font: {
                        size: 10px;
                        weight: 700;
                    }
                    line-height: 1.9rem;
                    color: rgba(color(black), 0.85);
                }
            }
        }
    }

    .page-footer-space {
        height: 85px;
    }
}

.print-delivery-note-for-customer {
    font-size: 1rem;

    @media print {
        .print-delivery-note--header {
            border-bottom: 1px solid rgba(color(black), 0.45) !important;
        }
    }

    table {
        tbody tr td > div {
            padding-right: 0.2rem;
        }
    }
    .ant-divider {
        margin: 1rem 0;
        background-color: rgba(color(black), 0.45);
    }
    .title {
        text-transform: uppercase;
    }
    h1.title {
        font: {
            size: 1.6rem;
        }
        margin-bottom: 0;
        margin-top: 1.5rem;
    }
    h2.title {
        font: {
            size: 1.4rem;
        }
        margin-bottom: 1.2rem;
    }
    .export-type {
        font: {
            size: 1.4rem;
        }
    }

    &--list {
        .package-list {
            .package-item {
                font-size: 1rem;
            }
        }
    }

    &--info {
        &.a-block-info {
            .info--label {
                display: inline-block;
                margin-right: 0.8rem;
                width: auto;
            }
        }

        .delivery-note--person {
            @include a-flex;

            border: 1px solid rgba(color(black), 0.45);
            justify-content: space-between;

            .person-action {
                font-size: 1.4rem;
                padding: 0.8rem;
                text-align: center;
                width: 50%;

                &:not(:last-child) {
                    border-right: 1px solid rgba(color(black), 0.45);
                }

                div.ant-typography {
                    margin-bottom: 4rem;
                }

                span {
                    font: {
                        weight: 700;
                    }
                }
            }
        }
    }
}

.print-delivery-note-FBU {
    @media print {
        html,
        body,
        ul,
        li,
        img {
            background-color: #ffffff !important;
            color: black !important;
            font-family: 'Roboto', sans-serif;
            height: auto !important;
            width: auto !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
        div.image-fbu {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            display: inline-block;
            width: 220px !important;
            height: 128px !important;
        }
        .list_product {
            .title,
            .table {
                padding-bottom: 24px;
            }
            .title {
                text-transform: uppercase;
            }
        }
        .footer {
            .COD {
                font-weight: 600;
            }
            .infomation {
                padding-left: 24px;
            }
        }
    }
}
