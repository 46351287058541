/* ==========================================================================
   # START PADDING ULTILITIES
   ==========================================================================
*/

.p {
  &-0 {
    padding: 0 !important;
  }

  &-1 {
    padding: (1rem * .25) !important;
  }

  &-2 {
    padding: (2rem * .25) !important;
  }

  &-3 {
    padding: (3rem * .25) !important;
  }

  &-4 {
    padding: (4rem * .25) !important;
  }

  &-5 {
    padding: (5rem * .25) !important;
  }

  &t {
    &-0 {
      padding-top: 0 !important;
    }

    &-1 {
      padding-top: (1rem * .25) !important;
    }

    &-2 {
      padding-top: (2rem * .25) !important;
    }

    &-3 {
      padding-top: (3rem * .25) !important;
    }

    &-4 {
      padding-top: (4rem * .25) !important;
    }

    &-5 {
      padding-top: (5rem * .25) !important;
    }
  }

  &b {
    &-0 {
      padding-bottom: 0 !important;
    }

    &-1 {
      padding-bottom: (1rem * .25) !important;
    }

    &-2 {
      padding-bottom: (2rem * .25) !important;
    }

    &-3 {
      padding-bottom: (3rem * .25) !important;
    }

    &-4 {
      padding-bottom: (4rem * .25) !important;
    }

    &-5 {
      padding-bottom: (5rem * .25) !important;
    }
  }

  &l {
    &-0 {
      padding-left: 0 !important;
    }

    &-1 {
      padding-left: (1rem * .25) !important;
    }

    &-2 {
      padding-left: (2rem * .25) !important;
    }

    &-3 {
      padding-left: (3rem * .25) !important;
    }

    &-4 {
      padding-left: (4rem * .25) !important;
    }

    &-5 {
      padding-left: (5rem * .25) !important;
    }
  }

  &r {
    &-0 {
      padding-right: 0 !important;
    }

    &-1 {
      padding-right: (1rem * .25) !important;
    }

    &-2 {
      padding-right: (2rem * .25) !important;
    }

    &-3 {
      padding-right: (3rem * .25) !important;
    }

    &-4 {
      padding-right: (4rem * .25) !important;
    }

    &-5 {
      padding-right: (5rem * .25) !important;
    }
  }

  &x {
    &-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &-1 {
      padding-left: (1rem * .25) !important;
      padding-right: (1rem * .25) !important;
    }

    &-2 {
      padding-left: (2rem * .25) !important;
      padding-right: (2rem * .25) !important;
    }

    &-3 {
      padding-left: (3rem * .25) !important;
      padding-right: (3rem * .25) !important;
    }

    &-4 {
      padding-left: (4rem * .25) !important;
      padding-right: (4rem * .25) !important;
    }

    &-5 {
      padding-left: (5rem * .25) !important;
      padding-right: (5rem * .25) !important;
    }
  }
}

.ap-8 {
  padding: .8rem !important;
}

.apl-8 {
  padding-left: .8rem !important;
}

.apr-8 {
  padding-right: .8rem !important;
}

.apt-8 {
  padding-top: .8rem !important;
}

.ap {
  &y {
    &-40 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
  }
}

.apr-24 {
  padding-right: 2.4rem !important;
}

.apr-36 {
  padding-right: 3.6rem !important;
}

.ap-24 {
  padding: 2.4rem !important;
}

.apt-24 {
  padding-top: 2.4rem !important;
}

.ap-16 {
  padding: 1.6rem !important;
}

.apl-16 {
  padding-left: 1.6rem !important;
}

.apr-16 {
  padding-right: 1.6rem !important;
}

.ap {
  &-16 {
    padding: 1.6rem !important;
  }

  &x {
    &-16 {
      padding-left: 1.6rem !important;
      padding-right: 1.6rem !important;
    }
  }

  &y {
    &-16 {
      padding-bottom: 1.6rem !important;
      padding-top: 1.6rem !important;
    }
  }
}

.pl-0-lg {
  @include media-breakpoint-down(lg) {
    padding-left: 0 !important;
  }
}

.phz-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pr-0-lg {
  @include media-breakpoint-down(lg) {
    padding-right: 0 !important;
  }
}

.apt-16 {
  padding-top: 1.6rem !important;
}

.apt-6 {
  padding-top: .6rem !important;
}

.apb-6 {
  padding-bottom: .6rem !important;
}

.apb-16 {
  padding-bottom: 1.6rem !important;
}

.phz-24 {
  padding-left: 2.4rem !important;
  padding-right: 2.4rem !important;
}

.pdt25 {
  padding-top: 25px;
}
.pdt150 {
  padding-top: 160px;
  height: 50px;
}

/* ==========================================================================
   # END PADDING ULTILITIES
   ==========================================================================
*/
