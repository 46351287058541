/* ==========================================================================
   # START SIDEBAR LAYOUT
   ==========================================================================
*/
/*Sider chính*/
.a-sider-main {
  position: fixed;
  left: 0;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  background-color: color(primary);
  width: width(sider-main-collapsed, lg) !important;
  min-width: width(sider-main-collapsed, lg) !important;
  max-width: width(sider-main-collapsed, lg) !important;
  z-index: 1000;
  @include customscrolltrack(.6rem, color(blue-F0), 1.4rem);
  @include css-transition('ease all .5s');
  @include media-breakpoint-up(xxk) {
    left: calc((100vw - 2560px) / 2);
  }

  &:hover {
    width: width(sider-main, lg) !important;
    min-width: width(sider-main, lg) !important;
    max-width: width(sider-main, lg) !important;

    .a-logo {
      .logo-collapse {
        display: none;
      }

      .logo-large {
        display: block;
      }
    }

    /*Only menu item*/
    .a-sider-main--item-only {

      .title {
        display: flex;
      }
    }

    /*Danh sách menu có menu nhỏ*/
    .a-sider-main--sub-menu {
      &.ant-menu-submenu-open {
        .ant-menu-sub {
          display: block;
        }
      }

      &.ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background-color: transparent;
        }
      }

      .ant-menu-submenu-title {
        .group-title {
          .title {
            display: block;
          }
        }

        .ant-menu-submenu-arrow {
          display: block;
        }
      }

      .ant-menu-sub {
        background-color: transparent;
        height: 100%;
        opacity: 1;
      }
    }

    .ant-menu-submenu-active {
      .ant-menu-submenu-title {
        .ant-menu-submenu-arrow {
          &:before {
            background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
          }

          &:after {
            background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
          }
        }
      }
    }
  }

  .a-logo {
    @include a-flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2.7rem .6rem;
    @include border-radius(.4rem);

    &:hover {
      cursor: pointer;
    }

    .logo-collapse {
      width: 4.2rem;
      height: 4.2rem;
      @include border-radius(.4rem);
    }

    .logo-large {
      width: 20.6rem;
      height: 4.2rem;
      transition: ease all .2s;
      object-fit: cover;
      display: none;
    }

    span {
      flex: 1;
      margin-left: 1rem;
      display: none;
      opacity: 0;
      white-space: nowrap;
      color: color(white);
      transition: ease all .2s;
      font: {
        size: font-size(large);
        weight: font-weight(medium)
      }
    }
  }

  /*Menu item only*/
  &--item-only {
    padding-left: 1.6rem !important;

    .title {
      display: none;
      margin-left: .9rem;
      color: color(white) !important;
      font-weight: font-weight(medium);
      margin-top: .3rem;
    }

    .icon {
      @include a-flex;
      justify-content: center;
      opacity: .65;
      width: 2.4rem;

      .anticon {
        margin: 0;

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }

    .group-item {
      display: flex !important;
      align-items: center;
    }
  }

  /*Danh sách menu con bên trong sider*/
  &--sub-menu {
    /*Submenu được mở*/
    &.ant-menu-submenu-open {
      .ant-menu-sub {
      }
    }

    /*Menu được chọn*/
    &.ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        background-color: rgba(color(black), .25);
        transition: ease all .4s;

        .title {
          opacity: 1 !important;
        }
      }

      .icon {
        opacity: 1 !important;
      }
    }

    /*Tên menu*/
    .ant-menu-submenu-title {
      @include a-flex;
      align-items: center;
      padding-left: 1.6rem !important;
      height: 5rem !important;

      &:hover {
        background-color: rgba(color(black), .25);

        .group-title {

          .icon {
            opacity: 1 !important;
          }

          .title {
            opacity: 1 !important;
          }
        }
      }

      .group-title {
        @include a-flex;
        align-items: center;

        .title {
          display: none;
          margin-left: .9rem;
          margin-top: .3rem;
          color: color(white);
          font-weight: font-weight(medium);
        }

        .icon {
          @include a-flex;
          justify-content: center;
          width: 2.4rem;
          opacity: .65;
        }
      }

      .ant-menu-submenu-arrow {
        display: none;

        &:before {
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
          background: color(gray-F3);
        }

        &:after {
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
          background: color(gray-F3);
        }
      }
    }

    .ant-menu-sub {
      background-color: transparent;
      height: 0;
      opacity: 0;
      transition: ease all .2s;

      .ant-menu-item {
        color: rgba(255, 255, 255, .65);

        &:hover {
          color: color(white);
        }
      }
    }
  }

  /*Danh sách menu*/
  &__menu {
    background-color: transparent !important;
    border-right: none;

    .ant-menu-item {
      @include a-flex;
      justify-content: flex-start;
      align-items: center;
      height: 5rem;

      &:hover {
        background-color: rgba(color(black), .25);

        .icon {
          opacity: 1;
        }
      }

      &.ant-menu-item-selected {
        background-color: rgba(color(black), .25);
        color: color(white);

        .icon {
          opacity: 1;
        }

        .title {
          opacity: 1;
        }

        &::after {
          border-right: 0;
        }
      }

      /*Menu được active*/
      &.ant-menu-item-active {
        &::after {
          border-right: 0;
        }

        background-color: rgba(color(black), .25);

        .icon {
          opacity: 1;
        }

        .title {
          opacity: 1;
        }
      }

      a {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
}

/*End*/
/*Sider phụ trong các trang*/
.a-sider-sub {
  width: width(sider, xxl) !important;
  min: {
    width: width(sider, xxl) !important;
  }
  max: {
    width: width(sider, xxl) !important;
  }
  height: calc(100vh - #{ get-map($heights, "header", "lg")} - 2.4rem);
  background-color: color(white);
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;
  @include css-transition('linear all .5s');
  @include media-breakpoint-down(lg) {
    width: width(sider, lg) !important;
    min: {
      width: width(sider, lg) !important;
    }
    max: {
      width: width(sider, lg) !important;
    }
    height: calc(100vh - #{ get-map($heights, "header", "lg")} - 1.6rem);

  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: color(gray-D9);
      border-radius: 1.4rem;
    }
  }

  &::-webkit-scrollbar {
    width: .6rem;

    &:horizontal {
      height: .6rem;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 1.4rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: color(gray-D9);
  }

  &--radius {
    @include border-radius(.4rem)
  }

  &__block {
    padding-right: 1.6rem;
    margin-bottom: 1.6rem;
    padding-left: 1.6rem;

    &__title {
      margin-bottom: 1.4rem;
      font: {
        size: font-size(large);
        weight: font-weight(regular);
      }
      color: rgba(color(black), .85);
    }
  }

  &.scroll {
    position: fixed;
    @include css-transition('linear all .5s');
    top: 0;
    height: 100vh;
  }

  /*Sider sub trong đóng bao*/
  &.bag-pack {
    overflow: hidden;

    .ant-tabs-content {
      height: calc(100% - 60px);
    }

    .bag-pack-detail {
      @include a-flex;
      flex-direction: column;
      height: 100%;
    }

    &.scroll {
      height: calc(100vh - .5rem);
      @include css-transition('linear,all,.5s');
    }

    .bag-pack--block {
      padding: 1.6rem 1.6rem 1.6rem 1.6rem;
      border-bottom: .1rem solid color(border);
      color: rgba(color(black), .85);

      .title {
        font-size: font-size(large);
      }

      .name-bag {
        margin-top: .7rem;
        margin-bottom: 0;
        font-size: font-size(large);
        text-transform: uppercase;
      }
    }

    .info-bag {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;

      &.scroll {
        height: 500px;
      }

      @include customscroll(.6rem, color(gray-D9), 1rem);

      .title {
        padding: 1.4rem 1.6rem .5rem 1.6rem;
        font: {
          size: font-size(medium);
          weight: font-weight(bold);
        }
        color: rgba(color(black), .85);
      }

      ul {
        padding: 0 .7rem 0 1.6rem;
        margin-bottom: 0;
        @include a-flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;

        @include customscrolltrack(.6rem, color(gray-D9), .7rem);

        li {
          list-style-type: none;
          @include a-flex;
          flex-direction: column;
          margin-top: 1.6rem;

          &:last-child {
            margin-bottom: 1.6rem;
          }

          .info-bag--label {
            width: 100%;
            margin-bottom: .4rem;
            font: {
              size: font-size(s-small);
            }
            color: rgba(color(black), .45);
          }

          .info-bag--value {
            font: {
              size: font-size(medium);
              weight: font-weight(regular);
            }
            color: #262626;
          }
        }
      }
    }

    .bags-created-today {
      padding: 0 10px;
      min-height: 100px;

      .bag-item {
        border-bottom: 1px solid #ccc;
        padding: 10px 0;

        .name {
          font-size: 16px;
          text-transform: uppercase;
        }

        .info {
          overflow: hidden;

          .lbel {
            font-size: 12px;
            color: #ccc;
          }

          .num {
            color: #4a4a4a;
          }

          .weight {
            margin-right: 40px;
          }
        }
      }
    }
  }
}

/*End*/
/*Menu trong configs*/
.a-menu--configs {
  min-width: width(menu-config, xxl) !important;
  width: width(menu-config, xxl) !important;
  height: calc(100vh - #{ get-map($heights, "header", "lg")} - 2.4rem);
  background-color: color(white);
  overflow-y: auto;
  border-right: 0;
  @include border-radius(.4rem);
  @include css-transition('linear all .5s');
  @include media-breakpoint-down(lg) {
    width: width(menu-config, lg);
    height: calc(100vh - #{ get-map($heights, "header", "lg")} - 1.6rem);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: color(gray-D9);
      border-radius: 1.4rem;
    }
  }

  &::-webkit-scrollbar {
    width: .6rem;

    &:horizontal {
      height: .6rem;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 1.4rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: color(gray-D9);
  }

  .ant-menu-item {
    color: color(gray);
    height: 5rem;
    line-height: 5rem;

    &.ant-menu-item-selected {
      position: relative;
      background-color: color(light-blue);
      color: color(primary);
      font-weight: 700;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: .2rem;
        content: '';
        background-color: color(primary);
        height: 100%;
        animation: height100 .2s linear;
      }
    }
  }
}

.a-sub-sider {
  width: width(sider, xxl) !important;
  min: {
    width: width(sider, xxl) !important;
  }
  max: {
    width: width(sider, xxl) !important;
  }
  min-height: calc(100vh - #{get-map($heights, 'header', 'lg')} - 2.4rem);
  background-color: color(white);
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;
  @include css-transition('linear all .5s');
  @include media-breakpoint-down(lg) {
    width: width(sider, lg) !important;
    min: {
      width: width(sider, lg) !important;
    }
    max: {
      width: width(sider, lg) !important;
    }
    min-height: calc(100vh - #{get-map($heights, 'header', 'lg')} - 1.6rem);

  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: color(gray-D9);
      border-radius: 1.4rem;
    }
  }

  &::-webkit-scrollbar {
    width: .6rem;

    &:horizontal {
      height: .6rem;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 1.4rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: color(gray-D9);
  }

  &--radius {
    @include border-radius(.4rem)
  }
}

/*End*/
/* ==========================================================================
   # END SIDEBAR LAYOUT
   ==========================================================================
*/
