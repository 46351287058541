#consignment-container{
  flex:1;
  display: flex;
  .min-h-screen{
    min-height: 100vh;
  }
  .form__item{
    padding-bottom: 0;
  }
  .consignment-footer{
    border-radius: 4px;
    position: fixed;
    width: calc(100% - 55px - 1.6rem - 24px);
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 6.4rem;
    bottom: 0;
    background-color: #FFFFFF;
    z-index: 100;
    @include media-breakpoint-down(lg) {
      width: calc(100% - 55px - 1.6rem - 16px);
    }
  }
  .icon-delete{
    cursor: pointer;
    padding: 4px 16px;
    transition: all 1s;
    :hover{
      color: #f5222d;
    }
  }
  .a--hidden{
    display: none;
    .ant-form-explain{
      display: none;
    }
  }
  .table_nested{
    .ant-pagination{
      margin: 16px;
    }
  }
  .warning_message{
    color: #f5222d;
    font-size: 12px;
    margin-top: 4px;
  }
  .flex{
    &.gap-24{
      gap: 24px;
    }
  }
  .text-white{
    color: white;
  }
}
