/* ==========================================================================
   # START PACKAGE PAGE
   ==========================================================================
*/
.a-package {
    &__title {
        h3 {
            margin-bottom: 0;
            font: {
                size: font-size(large);
                weight: font-weight(bold);
            }
            color: color(light-black);
            span {
                font: {
                    size: font-size(large);
                    weight: font-weight(medium);
                }
                > span {
                    color: color(primary);
                }
            }
        }
    }
    /*Chi tiết kiện*/

    /*end*/
    &__body {
        position: relative;
        padding: 0;
        padding-right: .3rem;
        border-radius: .4rem;
        .is_empty {
            @include a-flex;
            justify-content: center;
            align-items: center;
        }
        .is_form {
        }
        .form--package {
            /*Input nhập Kích thước trong package*/
            .group--size-package {
                @include media-breakpoint-down(lg) {
                    margin-top: 1.4rem;
                }
            }
            .group--input-bill-code-small {
                display: inline-flex;
                align-items: center;
            }
            .bill-code-sub {
                color: rgba(color(black), .85);
                white-space: nowrap;
            }
        }
        &--scroll {
            min-height: calc(100vh - #{ get-map($heights, "header", "lg")} - 10.2rem);
            max-height: calc(100vh - #{ get-map($heights, "header", "lg")} - 10.2rem);
        }
        &--group-size {
            @include a-flex;
            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
                //margin-top: 1.5rem;
            }

        }
        .package--info-left {
            border-right: .1rem solid color(border);
        }
        .box-weight-control {
            .box-weight-input {
                width: 14.5rem;
            }
        }
    }
    &__block {
        &--define-warehouse {
            padding: 1.4rem;
            background-color: color(light-gray);
            .define-warehouse--block {
                &:not(:last-child) {
                    margin-bottom: 2rem;
                }
                .define-warehouse-title {
                    margin-bottom: .5rem;
                    font: {
                        size: font-size(large);
                        weight: font-weight(regular);
                    }
                }
                ul {
                    padding-left: 1rem;
                    li {
                        list-style-type: none;
                        span {
                            font: {
                                size: font-size(small);
                            }
                        }
                    }
                }
            }
        }
    }
    &__scan-empty {
        @include a-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 2.4rem;
        margin-right: 2.4rem;
        padding-left: 0;
        background-color: color(white);
        @include border-radius(.4rem);
        @include media-breakpoint-down(lg){
            margin-left: 1.6rem;
            margin-right: 1.6rem;
        }
        img {
            width: 16.2rem;
            height: 16.2rem;
            object-fit: cover;
            margin-bottom: 1.6rem;
        }
        span {
            text-align: center;
            font: {
                size: font-size(xx-large);
                weight: font-weight(bold);
            }
            color: rgba(color(black), .25);
            text-transform: uppercase;
            line-height: 3rem;
        }
    }
    /*Hiển thị dịch vụ trong đơn*/
    &--service {
        padding-bottom: 1.2rem;
        border: .1rem solid color(border);
        @include border-radius(.4rem);
        &.only-view {
            border: none;
            border-left: .1rem solid rgba(color(black), .25);
            @include border-radius(0);
            &.is-border-left{
                @include media-breakpoint-down(lg){
                    border-left: .1rem solid rgba(color(black), .25);
                    margin-top: 0;
                }
            }
            .title {
                padding: 0;
                background-color: color(white);
                border-bottom: none !important;
                height:auto;
            }
            .service--body {
                padding: 0;
                .title--block {
                    font: {
                        size: font-size(s-small);
                        weight: font-weight(regular) !important;
                    }
                    color: rgba(color(black), .45);
                }
                .service--block {
                    border: none !important;
                    padding-left: 0;
                    .service--block--body {
                        margin-top: 1rem;
                        @include media-breakpoint-down(lg){
                            margin-top: 1rem;
                        }
                        ul {
                            li {
                                span {
                                    @include a-flex;
                                    align-items: center;
                                    padding-left: 0;
                                    font: {
                                        size: font-size(s-small);
                                        weight: font-weight(regular);
                                    }
                                    line-height: 2.8rem;
                                    color: rgba(color(black), .85);
                                    img {
                                        width: 2rem;
                                        height: 2rem;
                                        object-fit: cover;
                                        margin-right: 1rem;
                                    }
                                    svg {
                                        width: 2rem;
                                        height: 2rem;
                                        object-fit: cover;
                                        margin-right: 1rem;
                                    }
                                }
                            }

                            &._service_list {
                                li {
                                    span {
                                        svg {
                                            height: 1.6rem;
                                            width: 1.6rem;
                                        }
                                    }
                                }
                            }
                        }
                        .service-empty {
                            font: {
                                size: font-size(medium);
                                weight: font-weight(regular);
                            }
                            color: rgba(color(black), .45);
                        }
                    }
                }
            }
        }
        .title {
            padding: 1.2rem 1.2rem 1.2rem 1.4rem;
            font: {
                size: font-size(medium);
                weight: font-weight(bold);
            }
            color: rgba(color(black), .85);
            background-color: color(gray-FA);
            border-bottom: .1rem solid color(border);
            border-top-left-radius: .4rem;
            border-top-right-radius: .4rem;
        }
        .service--body {
            padding: 0;
            .service--block {
                padding-left: 1.2rem;
                max-height: 300px;
                overflow-y: auto;
                &:not(:last-child) {
                    border-bottom: .1rem solid color(border);
                }
                .title--block {
                    margin-top: 1.2rem;
                    font: {
                        size: font-size(s-small);
                        weight: font-weight(bold);
                    }
                }
                .service--block--body {
                    margin-top: 1.2rem;
                    .label--service {
                        font: {
                            size: font-size(s-small);
                            weight: font-weight(regular);
                        }
                    }
                    ul {
                        li {
                            list-style-type: none;
                            &:not(:last-child) {
                                margin-bottom: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
        &.inline-edit{
            &.only-view{
                padding-left: 2rem;
                @include media-breakpoint-down(lg){
                    padding-left: 1.2rem;
                    margin-top: 0;
                    border-left: .1rem solid color(border);
                }
            }
            .title{
                @include a-flex;
                @include media-breakpoint-down(xxl){
                    align-items: end;
                    flex-direction: row;
                }
                .icon-inline{
                    color: color(primary);
                    margin-left: .5rem;
                    animation: opacity-shadow .3s linear;
                    &:hover{
                        cursor: pointer;
                    }
                    @include media-breakpoint-down(xxl){
                        margin-top: .3rem;
                    }
                }
            }
        }
    }
    /*End*/
    /*Chi tiết kiện*/
    &--detail  {
        .title {
            font: {
                size: font-size(medium);
                weight: font-weight(bold);
            }
            span {
                font: {
                    weight: font-weight(regular);
                }
            }
        }
        .list-order {
            position: relative;
            width: 100%;
            margin-bottom: 1.6rem;
            padding: 1.25rem 2.4rem 1.4rem 2.4rem;
            background-color: color(white);
            @include border-radius(.4rem);
            @include media-breakpoint-down(lg){
                padding: 1.25rem 1.6rem;
            }
            &--top {
                @include a-flex;
                align-items: center;
                &--right {
                    @include a-flex;
                    margin-left: auto;

                }
            }
            &--detail {
                display: flex;
                flex-wrap: wrap;
                margin: 1.4rem -.4rem 0 -.4rem;
                @include media-breakpoint-down(lg){
                    margin-bottom: 0;
                }
                li {
                    margin-right: .5rem;
                    margin-left: .5rem;
                    margin-bottom: 1rem;
                    padding: .2rem 1rem;
                    list-style-type: none;
                    border: .1rem solid color(border);
                    @include border-radius(.4rem);
                    @include css-transition('ease all .4s');
                    &.active {
                        border: .1rem solid color(primary);
                        background-color: color(primary);
                        span {
                            color: color(white);
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    span {
                        font: {
                            size: font-size(small);
                            weight: font-weight(regular);
                        }
                        color: rgba(color(black), .85);
                    }
                }
            }
        }
    }
    /*End*/
    &--action {
        @include a-flex;
        justify-content: space-between;
        padding-top: 1.6rem;
        border-top: .1rem solid color(border);
        padding-bottom: 1.6rem;
        &.only-action {
            @include a-flex;
            justify-content: flex-end;
            padding-top: 1.6rem;
            border-top: .1rem solid color(border);
            padding-bottom: 1.6rem;
            &.create-bag{
                padding-top: 2.4rem;
                button{
                    padding-left: 3.2rem;
                    padding-right: 3.2rem;
                }
            }
        }
        &.full-action {
            @include media-breakpoint-down(lg) {
                padding-bottom: 1.6rem;
            }
            .list-action--right {
                li {
                    &.not-line-space {
                        @include media-breakpoint-down(lg) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        .list-action {
            @include a-flex;
            align-items: center;
            margin-bottom: 0;
            li {
                position: relative;
                @include a-flex;
                align-items: center;
                padding-right: 1.5rem;
                list-style-type: none;
                &.not-line-space {
                    &:before {
                        width: 0 !important;
                    }
                }
                &:not(:first-child) {
                    padding-left: 1.5rem;
                }
                &:not(:last-child) {
                    &:before {
                        position: absolute;
                        right: 0;
                        top: 15%;
                        content: '';
                        width: .1rem;
                        height: 70%;
                        background-color: rgba(color(black), .45);
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
                @include media-breakpoint-down(lg) {
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    /* trong kiểm kiện*/
    &--checking {
        .ant-checkbox-disabled + span,
        .ant-radio-disabled + span
        {
            color: color(light-green) !important;
        }
    }

}

.package-note {
    @include a-flex;

    &__icon {
        margin-right: 1rem;
    }

    &__content {
        @include a-flex;
        justify-content: space-between;
        width: 100%;
        &__description {
            font-size: font-size(medium);
            color: rgba(color(black), .45);
            line-height: 2.2rem;
            margin-bottom: 0;
        }

        &__description-small {
            line-height: 2.2rem;
            margin-bottom: 0;
            font-size: font-size(s-small);
            color: #B4B6B8;
        }
    }
}

.alert-split-package {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;

    &.has-message {
        background-color: #FFFBE6;
        border: 1px solid #F5222D;
        border-radius: 3px 0 3px 3px;
    }

    .icon {
        color: #F5222D;
        display: inline-block;
        margin-right: 9px;
        font-size: 15px;
    }
    .btn-split-package {
        background-color: #597ef7;
        color: #fff;
        font-weight: bold;
        margin: -.1rem -.1rem -.1rem 0;
        padding: 6.2px 15px;
    }
}

.link-order-auto{
    position: relative;
    .btn-auto-generate{
        position: absolute;
        right: 0;
        bottom: -34px;
        z-index: 123;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
}


.attention-package{
    // background-color: transparent;
    // border: unset;
    padding: 0 12px !important;
}

.warehouse-area-info {
    div {
        margin-bottom: 5px;
    }
    .info--label {
        line-height: 1.5rem;
    }

    .info--value {
        font-weight: bold;
        line-height: 1.5rem;
    }
}


/* ==========================================================================
   # END PACKAGE PAGE
   ==========================================================================
*/