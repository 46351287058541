/* ==========================================================================
   # START TEXT ULTILITIES
   ==========================================================================
*/
.ant-typography {
  color: rgba(color(black), .85);;
}

.a-text {
  font-size: font-size(medium);
  font-weight: font-weight(regular);

  &--link {
    color: color(primary) !important;
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &--blue {
    color: color(primary) !important;
  }

  &--red {
    color: rgba(color(red), 1) !important;
  }

  &--white {
    color: rgba(color(white), 1) !important;
  }

  &--gray-70 {
    color: color(gray-70) !important;
  }

  &--green {
    color: color(light-green) !important;;
  }

  &--black {
    color: color(light-black) !important;
  }

  &--gray {
    color: color(gray) !important;
  }

  &--black-45 {
    color: rgba(color(black), .45) !important;
  }

  &--black-25 {
    color: rgba(color(black), .25) !important;
  }

  &--black-65 {
    color: rgba(color(black), .65) !important;
  }

  &--orange {
    color: color(orange-FAA) !important;
  }

  &--bold {
    font-weight: font-weight(bold) !important;
  }

  &--medium {
    font-weight: font-weight(medium) !important
  }

  &--regular {
    font-weight: font-weight(regular) !important
  }

  &--uppercase {
    text-transform: uppercase !important;
  }

  &--regular {
    font-weight: font-weight(regular) !important;
  }

  &--medium {
    font-weight: font-weight(medium) !important;
  }

  &--warning {
    font-size: font-size(s-small);
    line-height: 1.6rem;
    color: color(orange-FAA) !important;
  }

  &--error {
    font-size: font-size(s-small);
    line-height: 1.8rem;
    color: rgba(color(red), 1) !important;
  }

  &--nowrap {
    white-space: nowrap !important;
  }
  &--pre-line {
    white-space: pre-line !important;
  }

  &--pre-wrap {
    white-space: pre-wrap !important;
  }

  &--fz-12 {
    font-size: font-size(s-small) !important;
  }
  &--fz-14 {
    font-size: font-size(medium) !important;
  }
  &--fz-16 {
    font-size: font-size(large) !important;
  }
  &--fz-18 {
    font-size: font-size(x-large) !important;
  }
  &--fz-24 {
    font-size: font-size(xx-large) !important;
  }
  &--fz-36 {
    font-size: 3.6rem !important;
  }

  &--uppercase {
    text-transform: uppercase !important;
  }

  &--right {
    text-align: right !important;
  }

  &--center {
    text-align: center !important;
  }

  &--cursor-pointer {
    &:hover {
      cursor: pointer !important;
    }
  }

  &--underline {
    text-decoration: underline !important;
  }
  &--italic {
    font-style: italic !important;
  }

  &--truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

/* ==========================================================================
   # END TEXT ULTILITIES
   ==========================================================================
*/
