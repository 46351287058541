/*Hiển thị dịch vụ trong đơn*/
.a-service {
    border: .1rem solid color(border);
    @include border-radius(.4rem);

    .ant-collapse-content-box {
        padding: 0 !important;
    }

    &.only-view {
        border-left: .1rem solid rgba(color(black), .25);
        @include border-radius(0);
        @include media-breakpoint-down(lg){
            border-left: 0;
            margin-top: 2rem;
        }
        &.is-border-left{
            @include media-breakpoint-down(lg){
                border-left: .1rem solid rgba(color(black), .25);
                margin-top: 0;
            }
        }
        .title {
            padding: 0;
            background-color: color(white);
            border-bottom: none !important;
        }
        .service--body {
            padding: 1.2rem 1.2rem 1.2rem 1.4rem;
            .title--block {
                font: {
                    size: font-size(s-small);
                    weight: font-weight(regular) !important;
                }
                color: rgba(color(black), .45);
            }
            .service--block {
                border: none !important;
                padding-left: 0;
                .service--block--body {
                    margin-top: 1.5rem;
                    @include media-breakpoint-down(lg){
                        margin-top: 1rem;
                    }
                    ul {
                        li {
                            span {
                                @include a-flex;
                                align-items: center;
                                padding-left: 0;
                                font: {
                                    size: font-size(s-small);
                                    weight: font-weight(regular);
                                }
                                line-height: 2.8rem;
                                color: rgba(color(black), .85);
                                img {
                                    width: 2rem;
                                    height: 2rem;
                                    object-fit: cover;
                                    margin-right: 1rem;
                                }
                                svg {
                                    width: 2rem;
                                    height: 2rem;
                                    object-fit: cover;
                                    margin-right: 1rem;
                                }
                            }
                        }

                        &.service--list {
                            li {
                                span {
                                    svg {
                                        height: 1.6rem;
                                        width: 1.6rem;
                                    }
                                }
                            }
                        }
                    }
                    .service-empty {
                        font: {
                            size: font-size(medium);
                            weight: font-weight(regular);
                        }
                        color: rgba(color(black), .45);
                    }
                }
            }
        }
    }
    .title {
        padding: 1.2rem 1.2rem 1.2rem 1.4rem;
        font: {
            size: font-size(medium);
            weight: font-weight(bold);
        }
        color: rgba(color(black), .85);
        background-color: color(gray-FA);
        border-bottom: .1rem solid color(border);
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }
    .service--body {
        padding: 0;
        .service--block {
            padding-left: 1.2rem;
            max-height: 300px;
            overflow-y: auto;
            &:not(:last-child) {
                border-bottom: .1rem solid color(border);
            }
            .title--block {
                margin-top: 1.2rem;
                font: {
                    size: font-size(s-small);
                    weight: font-weight(bold);
                }
            }
            .service--block--body {
                margin-top: 1.2rem;
                .label--service {
                    font: {
                        size: font-size(s-small);
                        weight: font-weight(regular);
                    }
                }
                ul {
                    li {
                        list-style-type: none;
                        &:not(:last-child) {
                            margin-bottom: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}