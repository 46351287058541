
.a-page--404 {
  position: relative;
  height: 100vh;

  .company-name {
    position: absolute;
    left: 50%;
    top: 6%;
    text-transform: uppercase;
    font: {
      size: 3.2rem;
      weight: font-weight(bold);
    };
    color: color(white);
    @include transformtxt(-50%, -50%);
    text-shadow: 4px 0px 0px rgba(255, 255, 255, .5), 9px 8px 0px rgba(0, 0, 0, 0.15);
  }

  &__detail {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 70rem;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    border-radius: .4rem;
    @include transformtxt(-50%, -50%);

    h2 {
      margin-top: 0;
      margin-bottom: 2.5rem;
      font: {
        size: 2rem;
        weight: font-weight(regular);
        family: 'Cabin', sans-serif;
      }
      text-transform: uppercase;
      color: color(white)

    }

    .button-back {
      padding: 1rem 2rem;
      color: color(white);
      background-color: transparent;
      @include border-radius(.4rem);
      border: .1rem solid color(white);
      position: relative;

      &:active {
        &::after {
          position: absolute;
          top: -.1rem;
          right: -.1rem;
          bottom: -.1rem;
          left: -.1rem;
          display: block;
          border: 0 solid color(blue-link);
          border-radius: inherit;
          opacity: .2;
          -webkit-animation: fadeEffect 5s cubic-bezier(.08, .82, .17, 1), waveEffect .4s cubic-bezier(.08, .82, .17, 1);
          animation: fadeEffect 2s cubic-bezier(.08, .82, .17, 1), waveEffect .4s cubic-bezier(.08, .82, .17, 1);
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          pointer-events: none;
          content: "";
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .title {
      position: relative;
      height: 24rem;

      h1 {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 0;
        font: {
          size: 25.2rem;
          weight: font-weight(max-bold);
          family: 'Montserrat', sans-serif;
        }
        color: color(white);
        text-transform: uppercase;
        @include transformtxt(-50%, -50%);
      }

      h3 {
        position: relative;
        margin: 0;
        padding-left: 1rem;
        text-transform: uppercase;
        color: color(white);
        letter-spacing: .3rem;
        font: {
          size: 1.6rem;
          weight: font-weight(bold);
          family: 'Cabin', sans-serif;
        }
      }
    }

  }
}

.a-page--403 {
  @extend .a-page--404;

  .company-name {
    position: absolute;
    left: 50%;
    top: 10%;
    text-transform: uppercase;
    font: {
      size: 3.2rem;
      weight: font-weight(bold);
    };
    color: #262626;
    @include transformtxt(-50%, -50%);
    text-shadow: 4px 0px 0px rgba(255, 255, 255, .5), 1px 3px 0px rgba(0, 0, 0, 0.15);

  }

  &__detail {
    @extend .a-page--404__detail;

    .title {
      h3 {
        color: #262626;
      }

      h1 {
        color: #262626;
      }
    }

    .button-back {
      color: #262626;
      border: .1rem solid #262626;
    }

    h2 {
      color: #262626;
    }
  }
}
