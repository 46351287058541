/* ==========================================================================
   # START SELECT COMPONENT
   ==========================================================================
*/
.a-select {
    .ant-select-selection__placeholder {
        color: color(gray) !important;
        font: {
            size: font-size(medium);
            weight: font-weight(regular);
        };
    }
    .ant-select-selection-selected-value {
        color: rgba(color(black), .85);
        font-weight: font-weight(regular);
    }
    &--w-100 {
        width: 100%;
    }
    &--h-30 {
        .ant-select-selection--single {
            height: 3rem;
        }
    }
    .ant-select-selection {
        border: .1rem solid color(gray-D9);
    }
    .icon__dropdown__select {
        font: {
            size: font-size(medium);
        }
        color: color(gray-70);
    }
    /*Chọn số lượng bản ghi hiện trên pagination*/
    &--select-number-show {
        width: 8rem;
        .ant-select-selection {
            @include border-radius(.6rem);
            color: rgba(color(black), .85);
        }
    }
    /* Chọn kho package*/
    &--warehouse {
        width: 100%;
        margin-left: .5rem;
        margin-right: .5rem;
        .ant-select-selection-selected-value {
            font: {
                size: font-size(medium);
            }
            color: rgba(color(black), .85);
        }
    }
    /*Select trong search content các danh sách */
    &--search-content {
        .ant-select-selection-selected-value {
            color: rgba(color(black), .85);
            font-weight: font-weight(regular);
        }
    }
    &--multiple {
        max-height: 20rem;
        overflow: auto;
    }
    &--action-bag-pack {
        width: 4rem !important;
        &.disabled-bag-pack{
            pointer-events: none;
            .ant-select-selection {
                border: .1rem solid color(gray-E0);
                border-left: .1rem solid color(gray-9F);
                background-color: color(gray-E0);
                .ant-select-arrow {
                    .icon--more {
                        background-color: color(gray-9F);
                        &:before {
                            background-color: color(gray-9F);
                        }
                        &:after {
                            background-color: color(gray-9F);
                        }
                    }
                }
            }
        }
        &--dropdown {
            width: 26.7rem !important;
            left: 9.6rem !important;
            @include media-breakpoint-down(lg) {
                width: 17rem !important;
                left: 8.6rem !important;
            }
            .ant-select-dropdown-menu{
                .ant-select-dropdown-menu-item{
                    padding: 1.2rem 1.4rem !important;
                }
            }
        }

        &.ant-select-focused {
            .ant-select-selection--single {
                box-shadow: none;
                border-left: .1rem solid color(white) !important;
            }
        }
        .ant-select-selection {
            width: 4rem;
            height: 4rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border: .1rem solid color(primary);
            border-left: .1rem solid color(white);
            background-color: color(primary);
            .ant-select-selection-selected-value{
                opacity: 0 !important;
            }
            .ant-select-arrow {
                font-size: font-size(icon-sider);
                color: color(white);
                .icon--more {
                    position: relative;
                    width: .4rem;
                    height: .4rem;
                    margin-top: .5rem;
                    margin-right: .5rem;
                    background-color: color(white);
                    @include border-radius(50%);
                    &:before {
                        position: absolute;
                        top: 0;
                        left: -.8rem;
                        content: '';
                        width: .4rem;
                        height: .4rem;
                        background-color: color(white);
                        @include border-radius(50%);
                    }
                    &:after {
                        position: absolute;
                        top: 0;
                        right: -.8rem;
                        content: '';
                        width: .4rem;
                        height: .4rem;
                        background-color: color(white);
                        @include border-radius(50%);
                    }
                }
            }
        }
    }
    /*Autocomplete quét mã kiện trong tạo tao*/
    &--auto-complete--bag-pack{
        &.ant-select-focused{
            .ant-select-selection{
                border: 0 !important;
            }
        }
    }
    &--auto-complete--customer{
        &.ant-select-focused{
            .ant-select-selection{
                border: 0 !important;
            }
        }
    }
    &--export-scan{
        width:28rem !important;
    }

    &--status-configs{
        margin-left: .7rem;
    }

    &--inventory-scan-warehouse {
        width: 20rem !important;
    }
}

/*Custom select ant*/
.ant-select-open .ant-select-selection {
    border-color: color(primary) !important;
}
.ant-select-dropdown-menu-item-selected, .ant-select-dropdown-menu-item-selected:hover {
    font-weight: font-weight(medium) !important;
}
.ant-select-focused {
    .ant-select-selection {
        border: .1rem solid color(primary) !important;
        box-shadow: 0 0 0 2px color(shadow-primary);
    }
}
.has-error .ant-select-selection, .has-error .ant-select-selection:hover {
    border: .1rem solid rgba(color(red), 1) !important;
    box-shadow: 0 0 0 2px rgba(color(red), .15);
}
/* ==========================================================================
   # END SELECT COMPONENT
   ==========================================================================
*/