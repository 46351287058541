
.a-item-package {
    background-color: color(gray-F5);
    padding: 1.6rem 1.6rem 1.6rem 1.6rem;
    border: .1rem solid color(border);
    flex-wrap: inherit;
    @include border-radius(0);
    &__top {
        margin-bottom: 1.4rem;
    }

    &__bottom {
        padding-top: 1.4rem;
        border-top: .1rem solid color(border);

        .note-customer {
            position: relative;

            &:before {
                position: absolute;
                top: 0;
                right: .8rem;
                content: '';
                width: .1rem;
                height: 100%;
                background-color: color(gray-E0);
            }
        }
    }

    &__avatar {
        @include a-flex;
        flex-direction: column;
        margin-right: 1.6rem;

        img {
            width: 6rem;
            height: 6rem;
            object-fit: cover;
        }

        span {
            margin-top: .5rem;
            white-space: nowrap;
            font: {
                size: font-size(s-small);
                weight: font-weight(regular);
            }
            line-height: 1.7rem;
        }
    }

    &-checking__avatar {
        @include a-flex;
        flex-direction: column;
        margin-right: 1.6rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        span {
            margin-top: .5rem;
            white-space: nowrap;
            font: {
                size: font-size(s-small);
                weight: font-weight(regular);
            }
            line-height: 1.7rem;
        }
    }


    &__info {
        @include a-flex;
        flex-direction: column;

        span {
            margin-top: .2rem;
            font: {
                size: font-size(s-small);
                weight: font-weight(regular);
            }
            line-height: 1.8rem;
            color: rgba(color(black), .45);
        }
    }

    &--link {
        font: {
            size: 1.4rem;
            weight: 400;
        }
        line-height: 1.8rem;
    }

    &__recived-package {
        @include a-flex;
        flex-direction: row;
    }
}

.info-package-checkpackage {
    .body {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        padding-bottom: 1.8rem;
        padding-top: 1.6rem;
    }
    .title {
        &.create-bag {
            height: 4rem;
        }
    }
}

.collapse--checkpackage {
    @include a-flex;
    justify-content: center;
    align-items: center;
    padding-top: .4rem;
    padding-bottom: .4rem;
    border-top:1px solid rgba(color(black), .15);
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem;
    background-color: color(white);
    &:hover {
        cursor: pointer;
    }

    a {
        font: {
            size: font-size(s-small);
            weight: font-weight(regular);
        }
        line-height: 1.6rem;

        &:focus {
            text-decoration: none;
        }
    }
}
.group-action-cross-check{
    @include a-flex;
    .action{
        width: 3rem;
        height: 3rem;
        @include a-flex;
        justify-content: center;
        align-items: center;
        color: color(primary);
        @include border-radius(.3rem);
        border: .1rem solid color(primary);
        &:hover{
            cursor: pointer;
        }
        &.active{
            background-color: color(primary);
            color: color(white);
        }
        &.disable-unactive {
            cursor: not-allowed;
            color: rgba(0, 0, 0, 0.25) !important;
            background-color: #f5f5f5 !important;
            border: 1px solid #d9d9d9 !important;
        }
        &.disable-active{
            cursor: not-allowed;
            background-color: color(primary);
            color: white;
            border: .1rem solid color(primary);
            opacity: .7;
        }
    }
}
.check-package-empty{
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    color:rgba(0,0,0,.25);
    img{
        width: 10rem;
        height: 10rem;
        margin-bottom: 1rem;
    }
}