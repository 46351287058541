/* ==========================================================================
   # START INPUT COMPONENT
   ==========================================================================
*/
.a-input {
    color: rgba(color(black), .85);
    font: {
        weight: font-weight(regular);
    }
    @include input-placeholder {
        color: color(gray) !important;
        font: {
            size: font-size(medium);
            weight: font-weight(regular);
        };
    }
    &:focus {
        border-color: color(primary);
        box-shadow: 0 0 0 2px color(shadow-primary);
    }
    &:hover {
        border-color: color(primary);
        box-shadow: 0 0 0 2px color(shadow-primary);
    }
    &:disabled{
        background-color: rgba(color(black), .04);
        color: rgba(color(black), 0.25);
        border-color: color(border);
        &:focus {
            border-color: color(border);
            box-shadow: none !important;
            background-color: rgba(color(black), .04);
        }
        &:hover {
            border-color: color(border);
            box-shadow: none !important;
            background-color: rgba(color(black), .04);
        }
    }
    &--w-100{
        width: 100%;
    }
    &--matching-code {
        width: 20rem !important;
        height: 3.2rem;
        @include border-radius(.4rem);
        border: none;
        z-index: 1;
        input {
            border: none;
        }
        .ant-select-selection__placeholder {
            font: {
                size: font-size(medium);
                weight: font-weight(regular);
            }
            color: rgba(color(black), .45);
        }
        &.detail-package {
            height: auto;
            border: .1rem solid color(border);
        }
        &--large {
            .ant-select-selection__placeholder {
                font: {
                    size: font-size(large);
                }
            }
        }
    }
    &--bill-code-small {
        width: 10rem;
        @include media-breakpoint-only(lg, xl) {
            width: 9rem;
        }
        @include input-placeholder {
            font: {
                size: font-size(s-small);
                weight: font-weight(regular);
            }
            color: color(gray-9A) !important;
        }
    }
    &--bill-code-large {
        width: 15rem;
        @include media-breakpoint-only(lg, xl) {
            width: 14rem;
        }
        @include input-placeholder {
            font: {
                size: font-size(large);
                weight: font-weight(regular);
            }
            color: color(gray-9A) !important;
        }
    }
    &--scan-code {
        &:focus {
            border-color: color(primary);
            box-shadow: 0 0 0 2px color(shadow-primary);
        }
        &:hover {
            border-color: color(primary);
            box-shadow: 0 0 0 2px color(shadow-primary);
        }
    }
    &--package-size {
        width: 6.4rem;
        @include media-breakpoint-only(lg, xl) {
            width: 5.4rem;
        }
        @include input-placeholder {
            color: color(gray) !important;
            font: {
                size: font-size(s-small);
                weight: font-weight(regular);
            };
        }
        &:focus {
            border-color: color(primary);
            box-shadow: 0 0 0 2px color(shadow-primary);
        }
        &:hover {
            border-color: color(primary);
            box-shadow: 0 0 0 2px color(shadow-primary);
        }
        &--large {
            @include input-placeholder {
                font: {
                    size: font-size(large);
                };
            }
        }

    }
    /*Disable input*/
    &--disable {
        background-color: rgba(color(black), .04);
        cursor: not-allowed;
        color: transparent;
        pointer-events: none;
        &:focus {
            border-color: color(border);
            box-shadow: none !important;
            cursor: not-allowed;
        }
        &:hover {
            border-color: color(border);
            box-shadow: none !important;
            cursor: not-allowed;
        }
    }
    /*End*/

    /*Texarea tối đa 3 dòng*/
    &--textarea {
        font: {
            size: font-size(medium);
            weight: font-weight(regular);
        }
        color: rgba(color(black), .85);
    }
    /*End*/

    /*Date time picker full width*/
    &--datetime-picker-fullwidth {
        width: 100%;
        .ant-input {
            color: rgba(color(black), .85) !important;
            font: {
                weight: font-weight(regular);
            }
        }
    }
    /*End*/

    /*Thông báo input warning*/
    &--warning {
        border: .1rem solid color(orange-FAA);
        &:hover {
            border: .1rem solid color(orange-FAA);
        }
    }
    &--error {
        border: .1rem solid rgba(color(red), 1);
        &:hover {
            border: .1rem solid rgba(color(red), 1);
        }
    }
    /*End*/

    /*Input search trong trang danh sách*/
    &--search {
        .ant-input-suffix {
            right: .7rem;
            top: calc(50% + 1px);
            i {
                font-size: font-size(large);
            }
        }
    }
    /*End*/

    /*Input trong chat box*/
    &--chatbox {
        height: 2.5rem;
        padding-right: 1.5rem;
        @include border-radius(.2rem);
        border: .1rem solid color(border);
        font: {
            size: font-size(small);
        }
        @include input-placeholder {
            font: {
                size: font-size(s-small);
                weight: font-weight(regular);
            }
            color: rgba(color(black), .65);

        }
    }
    /*End*/

    /*Input tìm kiếm mã đơn hàng trong chi tiết kiện*/
    &--search-order {
        input {
            border: .1rem solid color(border);
            @include border-radius(.4rem);
            @include media-breakpoint-down(lg) {
                min-height: 3.2rem;
                height: 3.2rem;
            }
        }
        .ant-input-suffix {
            right: .5rem;
            i {
                font-size: font-size(large);
                color: rgba(color(black), .25);
            }
        }
    }
    /*End*/
    /*Input nhập lý do khác khi chọn lý do lưu kho là ` khác`*/
    &--reaseon-save-warehouse {
        margin-left: 2rem;
        @include media-breakpoint-down(lg) {
            margin-left: 0;
        }
    }
    /*Chọn mẫu bao trong modal tạo bao*/
    &--search-template-bag {
        width: 18.5rem;
    }
    &--export-scan{
        width: 28rem;
        height: 4rem;
    }
    /*Input search canh title */
    &--search-inline-title {
        margin-top: .6rem;
        height: 2.1rem;
        width: 30rem;
        input {
            height: 2.1rem;
            border: none;
            &:focus {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }
        .ant-input-suffix{
            right: 100%;
            width: 1.8rem;
            height: 1.8rem;
            .ant-input-search-icon{
                font-size: 1.8rem;
                margin-left: 0;
            }
            .ant-input-clear-icon {
                position: absolute;
                left: 30rem;
                top: 3px;
            }
        }
    }

    &--search-global{
        input {
            width: 10rem;
            border: none;
            background-color: transparent;
            &:focus {
                border: none;
                box-shadow: none;
                width: 20rem;
                transition: width .4s linear;
            }
            &:hover {
                border:none;
                box-shadow: none;
            }
        }
        .ant-input-suffix {
            right: 100%;
        }
    }
    &--inventory-scan {
        width: 26.8rem;
    }

    &--h-25 {
        height: 2.5rem !important;
    }

    &--small-placeholder {
        @include input-placeholder{
            font-size: font-size(s-small);
        }
    }
}
.has-error .ant-input, .has-error .ant-input:hover {
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.15);
}

/*config radio*/
.ant-radio-wrapper {
    @include a-flex;
    white-space: normal;

    .ant-radio {
        height: 1.6rem;
    }
}

.ant-radio-checked .ant-radio-inner {
    border-color: color(primary)
}

.ant-radio-inner::after {
    background-color: color(primary);
}

.ant-radio-checked::after {
    border: .1rem solid color(primary);
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: color(primary);
}

/*Checkbox*/
.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: color(primary);
    border-color: color(primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: color(primary);
}
/* ==========================================================================
   # END INPUT COMPONENT
   ==========================================================================
*/
