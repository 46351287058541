/* ==========================================================================
   # START BARCODE-SCAN PAGE
   ==========================================================================
*/
.a-barcode-scan{
    &__top{
        @include a-flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.5rem;
        background-color: color(white);
        @include border-radius(.4rem);
    }
    &__body{
    }
    &__block-scroll{
        min-height: calc(100vh - #{ get-map($heights, "header", "lg")} - 14rem);
        max-height: calc(100vh - #{ get-map($heights, "header", "lg")} - 14rem);
        padding-right: 1.2rem;
    }
    &__title{
        .title-small{
            font:{
                size: font-size(s-small);
                weight: font-weight(regular);
            };
        }
    }
    &--empty{
        @include a-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span{
            text-transform: uppercase;
        }
    }
}
/* ==========================================================================
   # END BARCODE-SCAN PAGE
   ==========================================================================
*/