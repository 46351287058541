/* ==========================================================================
   # START COLLAPSE COMPONENT
   ==========================================================================
*/
.a-collapse {
    &--package {
        border: none;
        border-radius: .4rem;
        background-color: transparent;
        position: relative;
        & >.ant-collapse-header {
            padding-left: 5.2rem !important;
            @include media-breakpoint-down(lg){
                padding-left: 4rem !important;
            }
            .ant-collapse-arrow {
                left: 2.4rem !important;
                @include media-breakpoint-down(lg){
                    left: 1.6rem !important;
                }
            }
        }
        &:not(:last-child) {
            margin-bottom: 1.6rem;
            @include media-breakpoint-down(lg){
                margin-bottom: 1.4rem;
            }
        }
        &__block-absolute-heading {
            position: absolute;
            top: .5rem;
            right: 2.4rem;
            @include a-flex;
            @include media-breakpoint-down(lg){
                right: 1.6rem;
            }
            .info-matching_code {
                margin-top: .5rem;
                margin-bottom: 0;
                color: color(white);
                z-index: z-index(sticky);
                span {
                    font-weight: font-weight(regular);
                }
            }
        }
        &__title {
            margin-top: .2rem;
            margin-bottom: 0;
            font: {
                size: font-size(medium);
                weight: font-weight(bold);
            }
            color: color(white);
            span {
                font: {
                    weight: font-weight(regular);
                }
            }
        }
        &__item {
            position: relative;
            margin-bottom: 1rem;
            border-bottom: none !important;
            background-color: color(white);
            border-radius: .4rem !important;
            @include media-breakpoint-down(lg){
                margin-bottom: 0;
            }
            & > .ant-collapse-header {
                height: 5rem;
                border: .1rem solid color(primary);
                color: color(white) !important;
                background-color: color(primary);
                border-radius: .4rem .4rem 0 0 !important;
            }
            .ant-collapse-content {
                border-radius: 0 0 .4rem .4rem !important;
                @include box-shadow(0, 0, .6rem, .1rem, rgba(color(black), 0.02))
            }
            .ant-collapse-content-box {
                padding: 2.4rem 2.4rem 0 2.4rem !important;
                @include media-breakpoint-down(lg){
                    padding: 1.6rem 1.6rem 0 1.6rem !important;
                }
            }
        }
        &__body {
            padding-bottom: 3.2rem;
        }
    }
    &--info-customer {
        border: none !important;

        .ant-collapse-content {
            border: none !important;
            box-shadow: none !important;
        }
        .ant-collapse-item {
            border: none !important;
            .ant-collapse-header {
                border: none !important;
                background-color: color(white);
                color: color(primary) !important;
                position: absolute;
                top: -12rem;
                right: 3rem;
                padding: 0;
                height: auto;
                z-index: z-index(sticky);
            }
        }
        .ant-collapse-content-box {
            padding: 0 !important;
        }
    }
}

.ant-collapse {
    .ant-collapse-item {
        &.bg-primary {
            .ant-collapse-header {
                color: color(white);
            }

            .ant-btn[disabled] {
                color: rgba(255, 255, 255, 0.25)
            }
        }
    }
}

._panel-item-lastmile-carrier {
    .ant-collapse-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    table thead tr th {
        background-color: white !important;
    }
}
/* ==========================================================================
   # END COLLAPSE COMPONENT
   ==========================================================================
*/